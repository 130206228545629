<template>
  <section>
    <p class="text-muted small mb-q">{{ $t('adsprofile.fileLimitation', { KB: 100 }) }}</p>
    <p class="text-muted small">{{ $t('adsprofile.pollImagesCaption') }}</p>

    <div v-for="(item, index) in value" :key="index" class="mb-1">
      <div class="input-group">
        <div class="input-group-addon">{{ index + 1 }}</div>

        <label :for="`poll-input-${index}`" class="visually-hidden">
          {{ item.text }}
        </label>
        <input
          :id="`poll-input-${index}`"
          :ref="`${index}`"
          type="text"
          class="form-control input"
          :value="item.text"
          @input="update(index)"
        />

        <button
          type="button"
          class="btn btn-danger fa fa-remove"
          :aria-label="$t('general.delete')"
          @click="remove(index)"
        />
      </div>

      <div class="mt-h d-flex align-items-center">
        <button
          class="img-preview"
          :style="`background-image: ${item.image !== '' ? `url('${item.image}')` : placeholder};`"
        >
          <label class="img-upload fa fa-file-image-o m-0 p-0">
            <input type="file" :ref="`${index}-upload`" class="upload" @change="upload(index)" />
          </label>
        </button>
        <h4 class="text-preview ml-1">{{ item.text }}</h4>
      </div>
    </div>

    <button class="btn btn-outline-secondary w-100" @click="add">
      <i class="fa fa-plus mr-h" />
      {{ $t('adsprofile.addVariant') }}
    </button>
  </section>
</template>

<script>
import helpers from '../../helpers';

export default {
  name: 'PollImages',
  props: ['value'],
  data() {
    return {
      placeholder:
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAADnUlEQVR4nO3d2U4bSRiA0Wa9Ynt9XpL9inVUYMkJE38TIHaT8TlS7twiLvWnbkulv3bOz89fJuCXdi0LrCYQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoGwb3Hms7OzMx0cHEx7e3u//D88PT1NDw8P08vLy3YsyDckkJkcHh5Ox8fH0+5uP8Sfn5+n29vb6f7+/n+8Gt+XV6wZjKfG6enpf8YxjM+Mz45r2DyBzODo6OjDf/Qz1/B1Atmw8UTY3//4m+245neeOPxZVnzDvnKTC2TzrDgEgUAQCASBQBAIBIFAEAgEgUAQCASBQBAIBIFAEAgEgUAQCASBQBAIBIFAEAgEgUAwOG6FMSBhHUMSVk1RXPe1ZQynG//4N4G8Mwa0jRlUnxnNs24nJydr+wuPj4/T3d3d66hTlrxi/WCMAz07O/uWcazb+M7ju481YEkgC2OQ9JiVu+3GGoy14I1AFsarlcFsb7+9zAFeckcsrOsH8N/IWiwJBIJAIAgEgkAgCASCQBbGgZlYi/cEsjC2WNiP9LYvy3aTJYEsjKOWx2my226sgWOnlwTyg3HU8tXV1evGvW0zvvP47o6b/pndvO+M14vLy8u1bnf/7K7cm5ubtfw+sN19NYGs8B1vmhHHNj7d5uQVC4JAIAgEgkAgCASCQCAIBIJAIAgEgkAgCASCQCAIBIJAIAgEgkAgCASCQCAIBIJAIAhkw74yCMLkkc0TyIaNm/wzk0nGNQLZPIHMYJwm+1GfuYavE8gMxnC66+vr33oijM+Mz5qXOw+D42YyRnxeXFy8Hpi56kzAMShuhGFW7nwEMqNx45uF+715xYIgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBIBAIAoEgEAgCgSAQCAKBVaZp+geKDLHjZTkiSAAAAABJRU5ErkJggg==')"
    };
  },
  methods: {
    add() {
      this.$emit('input', [...this.value, { text: '', image: '' }]);
    },
    remove(index) {
      this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
    },
    update(index) {
      this.$emit('input', [
        ...this.value.slice(0, index),
        {
          text: `${this.$refs[`${index}`][0].value}`,
          image: this.value[index].image
        },
        ...this.value.slice(index + 1)
      ]);
    },
    upload(index) {
      const file = this.$refs[`${index}-upload`][0].files[0];

      if (file === undefined) {
        return;
      }

      helpers.getBase64(file).then((data) => {
        this.$emit('input', [
          ...this.value.slice(0, index),
          {
            text: this.value[index].text,
            image: data
          },
          ...this.value.slice(index + 1)
        ]);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.img-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  width: 140px;
  height: 140px;
  margin: 0;
  padding: 0;

  border-radius: 50%;
  border: none;
  background-color: #acacac;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.img-upload {
  display: none;
  justify-content: center;
  align-items: center;

  width: 140px;
  height: 140px;

  color: #ffffff;
  font-size: 30px;

  background-color: rgba(0, 0, 0, 0.3);
  border: none;
}

.img-preview:hover .img-upload,
.img-preview:focus .img-upload {
  display: flex;
}

.text-preview {
  overflow: hidden;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
