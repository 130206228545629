<template>
  <div>
    <div v-if="isClickToGoToAnotherPage"><Loader-spinner-small centered /></div>
    <div v-if="isAdStatsShowing === true">
      <Ads-stats
        :dateStart="dateStartForStatistic"
        :adName="adNameForViewsStatistic"
        :adId="adIdForViewsStatistic"
        @closeAdsStats="closeAdsStats"
      ></Ads-stats>
    </div>
    <div v-else class="row animated fadeIn">
      <div class="col-lg-12 d-flex flex-column">
        <div class="float-right ml-auto">
          <button v-if="!isDisable" class="btn btn-info btn-md ml-aut o" @click="openAdModal()">
            {{ $t('adsprofile.add') }}
          </button>
        </div>
<!--        <Table-pagination-v2-->
<!--          v-model="filters"-->
<!--          :on-submit="getWithQuery.bind(this)"-->
<!--          :show-filters="['search', 'sort', 'limit']"-->
<!--          :options="[-->
<!--            { value: 'name', label: $t('general.name') },-->
<!--            { value: 'description', label: $t('general.description') },-->
<!--            { value: 'data.type', label: $t('adsprofile.adtype') }-->
<!--          ]"-->
<!--        />-->

        <Table-pagination-v3
          v-if="showTablePaginationV3Component"
          small-width-location-selector
          small-height-for-custom-multiselect-and-select-components
          v-model="filters"
          :on-submit="getWithQuery.bind(this)"
          :show-filters="['search', 'sort', 'limit', 'location']"
          :options="[
            { value: 'name', label: $t('general.name') },
            { value: 'description', label: $t('general.description') },
            { value: 'data.type', label: $t('adsprofile.adtype') }
          ]"
        />

        <div class="col-lg-12">
          <div class="row">
            <div class="filters">
              <div class="filters__search"></div>
              <div class="filters__multiple">
                <div class="btn-group btn-group-sm float-left" data-toggle="button">
                  <label
                    class="btn btn-outline-info"
                    v-for="filter in adsType"
                    :key="filter"
                    :class="{
                      active: additionalFilters.ad_types.includes(filter),
                      'btn-outline-info':
                        (additionalFilters.ad_types.includes('poll') && filter === 'poll') ||
                        (additionalFilters.ad_types.includes('poll_quality') && filter === 'poll_quality') ||
                        (additionalFilters.ad_types.includes('poll_user_data') && filter === 'poll_user_data'),
                      'btn-outline-warning': additionalFilters.ad_types.includes('video') && filter === 'video',
                      'btn-outline-success': additionalFilters.ad_types.includes('image') && filter === 'image'
                    }"
                  >
                    <input type="radio" name="activeFilter" @click="setFilter(filter)" />
                    {{ $t(`adsprofile.${filter}`).toUpperCase() }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-lg-4 pl-0">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <button
                      v-if="isFiltersActive"
                      class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </button>
                  </div>
                </div>
              </div>

      </div>
        </div>

      </div>

      <div v-if="isPortalAdsLoading" class="col-lg-12 animated fadeIn">
        <div class="row">
          <div class="col-lg-12">
            <!--            <div class="loader loader-backdrop loader-backdrop-portalads"></div>-->
            <div><Loader-spinner /></div>
          </div>
        </div>
      </div>
      <div v-else class="col-lg-12 animated fadeIn">
        <br />
        <div class="card-block p-0">
          <i class="fa fa-money" />
          {{ $t('sidebar.ads') }}
          <Checkbox-component
            name="checkboxInCardHeader"
            id="checkboxCheckAll"
            :label="$t('adsprofile.massAdsEditSelectAll')"
            :checked="allAdsSelected === true"
            class="float-right"
            @click="toggleSelectAllAds"
          />

          <!--                <span class="text-muted ml-3" v-if="portalAdsWithStats.length">-->
          <!--                  {{ this.portalAdsWithStats.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultAds }}-->
          <!--                </span>-->
        </div>
        <br />
        <div class="row flex-lg-row">
          <!--          <div v-if="isVideosLoading"><Loader-spinner /></div>-->
          <div class="col-xl-8 col-lg-12 order-xl-1 order-lg-2">
            <!--            <div v-if="isPortalAdsLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
            <div v-if="isPortalAdsLoading"><Loader-spinner /></div>
            <div class="row">
              <Data-iterator-card
                v-for="ad in portalAdsWithStatsFiltered"
                class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                :title="ad.name"
                :key="ad.id"
                :status="ad.campaignStatus"
              >
                <template #checkbox>
                  <Checkbox-component
                    name="checkboxInCardHeader"
                    :id="`checkboxInCardHeader-${ad.id}`"
                    :checked="selectedAdsId.includes(ad.id)"
                    @click="selectAd(ad.id)"
                  />
                </template>
                <template #header-icons>
                  <span
                    v-if="ad.statistics"
                    class="icon-button-hover px-q d-flex align-items-center"
                    @click="showAdShowsStats(ad.id)"
                    v-tooltip="{ content: $t('adsprofile.statisticsShows') }"
                  >
                    <i v-if="shownAdShowsStats.includes(ad.id)" class="fa fa-lg fa-eye-slash" />
                    <i v-else class="fa fa-lg fa-eye" />
                  </span>
                  <span
                    v-if="(ad.data.type === 'poll' || ad.data.type === 'poll_quality') && ad.statistics"
                    class="icon-button-hover px-q d-flex align-items-center"
                    @click="openPollResults(ad)"
                    v-tooltip="{ content: $t('adsprofile.pollResults') }"
                  >
                    <i class="fa fa-lg fa-list" />
                  </span>
                  <span
                    class="icon-button-hover px-q d-flex align-items-center"
                    @click="openAdModal(ad)"
                    v-tooltip="{ content: $t('adsprofile.edit') }"
                  >
                    <i class="fa fa-lg fa-pencil-square-o" />
                  </span>
                </template>
                <template #card-body>
                  <div class="ads-status-in-card d-flex justify-content-between align-items-center">
                    <span v-if="checkAdActivityStatus(ad) === 'active'" class="ads-schedule__status--active">
                      <i class="fa fa-circle" />
                      {{ $t(`adsprofile.activeAd`) }}
                    </span>
                    <span v-else-if="checkAdActivityStatus(ad) === 'planned'" class="ads-schedule__status--planned">
                      <i class="fa fa-circle" />
                      {{ $t(`adsprofile.plannedAd`) }}
                    </span>
                    <span v-else-if="checkAdActivityStatus(ad) === 'done'" class="ads-schedule__status--done">
                      <i class="fa fa-circle" />
                      {{ $t(`adsprofile.doneAd`) }}
                    </span>
                    <div v-if="ad.data.url_desktop || ad.data.url">
                      <span v-if="ad.data.url" class="px-h">
                        <i
                          class="fa fa-2x"
                          v-tooltip="{ content: $t('adsprofile.platformMobile') }"
                          :class="{
                            'fa-mobile': ad.data.url
                          }"
                        ></i>
                      </span>
                      <span v-if="ad.data.url_desktop" class="px-h">
                        <i
                          class="fa fa-2x"
                          v-tooltip="{ content: $t('adsprofile.platformDesktop') }"
                          :class="{
                            'fa-desktop': ad.data.url_desktop
                          }"
                        ></i>
                      </span>
                    </div>
                    <span v-else></span>
                  </div>
                  <div v-if="shownAdShowsStats.includes(ad.id) && ad.statistics" class="h-100">
                    <div class="row">
                      <div v-if="ad.statistics.counter" class="mt-h col-6">
                        <h3 class="m-0">
                          {{ ad.statistics.counter }}
                        </h3>
                        <small class="text-uppercase">{{ $t('adsprofile.statisticsTotal') }}</small>
                      </div>
                      <div v-if="ad.statistics.counter_skipped" class="mt-h col-6">
                        <h3 class="m-0">
                          {{ ad.statistics.counter_skipped }}
                        </h3>
                        <small class="text-uppercase">{{ $t('adsprofile.statisticsSkipped') }}</small>
                      </div>
                      <div v-if="ad.statistics.duration" class="mt-h col-6">
                        <h3 class="m-0">
                          {{ ad.statistics.duration }}
                        </h3>
                        <small class="h5">{{ $t('general.sec') }}</small>
                        <br />
                        <small class="text-uppercase">{{ $t('adsprofile.statisticsDuration') }}</small>
                      </div>
                      <div v-if="ad.statistics.duration && ad.statistics.counter" class="mt-h col-6">
                        <h3 class="text-nowrap m-0">
                          {{ (ad.statistics.duration / ad.statistics.counter) | maxDigits }}
                        </h3>
                        <small class="h5">{{ $t('general.sec') }}</small>
                        <br />
                        <small class="text-uppercase">{{ $t('adsprofile.statisticsMeanDuration') }}</small>
                      </div>
                    </div>
                  </div>
                  <poll-preview
                    v-else-if="
                      ad.data.type === 'poll' || ad.data.type === 'poll_quality' || ad.data.type === 'poll_user_data'
                    "
                    :key="`poll-preview-${ad.id}`"
                    :type="ad.data.type"
                    :ad="ad"
                    :theme-color="ad.data.theme_color"
                    :theme-type="ad.data.theme_type"
                  />
                  <!--                                   <div-->
                  <!--                    v-else-->
                  <!--                    class="d-flex m-auto w-100 align-items-center overflowY-hidden"-->
                  <!--                    v-html="showAd(ad.data, ad.id)"-->
                  <!--                  ></div>-->
                  <template v-else>
                    <div style="position: relative;" class="d-flex m-auto w-100 align-items-center">
                      <div v-if="ad.data.type === 'video' && isVideosLoadingForAdId(ad.id) === true">
                        <!--                        тут выводим спиннеры на время загрузки видео-->
                        <Loader-spinner-small centered />
                      </div>
                      <div v-if="ad.data.type === 'video' && isVideosLoadingForAdId(ad.id) === 'error'">
                        <!--                        а тут сообщение если видео не подгрузилось (например 404)-->
                        <div class="video-error-wrapper">
                          <div class="video-error-container">
                            <i class="fa fa-remove text-danger">
                              <span class="ml-h">{{ $t('adsprofile.videoLoadingError') }}</span>
                            </i>
                          </div>
                        </div>
                      </div>
                      <div
                        class="d-flex m-auto w-100 align-items-center overflowY-hidden"
                        v-html="showAd(ad.data, ad.id)"
                      ></div>
                      <div v-if="ad.data.type === 'video' && isVideosLoadingForAdId(ad.id) === 'error'">
                        <!--                        а тут кнопку для обновления видео если видео не подгрузилось (например 404)-->
                        <div class="video-reload-wrapper">
                          <div class="video-reload-container">
                            <span @click="reloadVideoByAdId(ad.id)" role="button">
                              {{ $t('adsprofile.videoLoadingErrorReload') }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template #card-footer>
                  <button
                    class="btn btn-block btn-primary text-truncate"
                    :class="{
                      'btn-info': ad.data.type === 'poll' || 'poll_quality' || 'poll_user_data',
                      'btn-success': ad.data.type === 'image',
                      'btn-warning': ad.data.type === 'video'
                    }"
                    @click.stop="openAdsStats(ad.id, ad.name)"
                  >
                    {{ $t('adsprofile.adsStatsbyId') }}
                  </button>
                </template>
              </Data-iterator-card>
            </div>
            <div
              v-if="
                this.canLoadMoreAds &&
                this.portalAdsWithStats.length &&
                this.totalResultAds - this.portalAdsWithStats.length
              "
              class="card-block text-center p-0"
            >
              <button class="btn btn-block" @click="loadMore">
                {{ this.$t('portal.paginationLoad') }}
                <span v-if="this.filters.limit < this.totalResultAds - this.portalAdsWithStats.length">
                  {{ this.filters.limit }}
                </span>
                <span v-else>{{ this.totalResultAds - this.portalAdsWithStats.length }}</span>
                {{ this.$t('portal.paginationAdsOutOf') }}
                {{ this.totalResultAds - this.portalAdsWithStats.length }}
              </button>
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 order-xl-2 order-lg-1" v-if="!isAdsSelected">
            <div class="card sticky-top">
              <!--              <div v-if="isPortalAdsLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
              <div v-if="isPortalAdsLoading"><Loader-spinner /></div>
              <div class="card-header">
                <i class="fa fa-calendar-o" />
                {{ $t('adsprofile.schedule') }}
              </div>
              <div class="card-block">
                <div class="row mb-1 align-items-center">
                  <p class="h5 col-5 m-0">
                    {{ $t('adsprofile.scheduleDate') }}
                  </p>
                  <date-picker
                    v-model="scheduleCutoffDate"
                    :range="false"
                    format="D MMMM YYYY"
                    type="date"
                    class="col-7"
                  />
                </div>

                <ul v-if="portalAdsByStart.length" class="ads-schedule">
                  <li v-for="ad in portalAdsByStart" :key="`campaign-card-${ad.id}`">
                    <article class="card card-hover" @click="redirectTo(`#${ad.name}`)" role="button">
                      <h2 class="card-header d-flex">
                        {{ ad.name }}
                        <span class="ml-auto" :class="`ads-schedule__status--${ad.campaignStatus}`">
                          <i class="fa fa-circle" />
                          {{ $t(`adsprofile.${ad.campaignStatus}Ad`) }}
                        </span>
                      </h2>
                      <div class="card-block">
                        <p class="card-text">{{ ad.schedule.start | moment }} — {{ ad.schedule.stop | moment }}</p>
                      </div>
                    </article>
                  </li>
                </ul>
                <p v-else class="text-muted">{{ $t('adsprofile.scheduleEmpty') }}</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 order-xl-2 order-lg-1" v-if="isAdsSelected">
            <!--            Mass Edit Actions-->
            <div class="card sticky-top">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('aps.multiEdit') }}
                </div>
                <div>
                  <span class="text-muted">{{ $t('adsprofile.massAdsEditSelected') }}: {{ selectedAdsId.length }}</span>
                </div>
                <div>
                  <span role="button" @click="clearSelectedAdsId"><i class="fa fa-close"></i></span>
                </div>
              </div>
              <div class="card-block">
                <div class="d-flex flex-column align-items-center mb-1">
                  <div class="w-100">
                    <button class="btn btn-primary btn-info mt-1 w-100" @click="massAdsEditScheduleShowModal">
                      {{ $t('adsprofile.massAdsEditSchedule') }}
                    </button>
                  </div>
                  <div class="w-100">
                    <button class="btn btn-primary btn-info mt-1 w-100" @click="massAdsEditTargetingShowModal">
                      {{ $t('adsprofile.massAdsEditTargeting') }}
                    </button>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div>
                    <button class="btn-outline-secondary btn btn-sm" @click="clearSelectedAdsId">
                      <i class="fa fa-close"></i>
                      {{ $t('general.cancel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        v-model="isAdsModalOpen"
        class="modal-info modal-info-in-ads"
        effect="fade/zoom"
        xlarge
        :backdrop="false"
        @cancel="cancelEditMode"
        @click.native="handleModalClick"
        closeBtn
      >
        <!-- Modal header -->
        <h4 slot="modal-title" class="modal-title">
          <span>{{ isNewAd ? $t('adsprofile.new') : $t('adsprofile.edit') }}</span>
        </h4>

        <!-- New Modal body -->
        <div v-if="updatedAd" class="row">
          <!-- left side -->
          <div class="col-7">
            <!-- Type select -->
            <div class="row">
              <!-- Slider desktop -->
              <div v-if="updatedAd.data.type === 'image'" class="col-6">
                <div class="form-group">
                  <label for="has-desktop">{{ $t('adsprofile.preview') }}</label>
                  <select
                    id="has-desktop"
                    v-model="hasDesktop"
                    :class="{ 'select-disabled': isDisable }"
                    :disabled="isDisable"
                    name="has-desktop"
                    class="form-control"
                  >
                    <option :value="true">{{ $t(`adsprofile.previewDesktop`) }}</option>
                    <option :value="false">{{ $t(`adsprofile.previewMobile`) }}</option>
                  </select>
                </div>
              </div>
              <div
                class="form-group"
                :class="{ 'col-12': updatedAd.data.type !== 'image', 'col-6 ': updatedAd.data.type === 'image' }"
              >
                <label for="ad_type">{{ $t('adsprofile.adtype') }}</label>
                <select
                  id="ad_type"
                  v-model="updatedAd.data.type"
                  :class="{ 'select-disabled': !isNewAd || isDisable }"
                  :disabled="!isNewAd || isDisable"
                  name="ad_type"
                  class="form-control"
                >
                  <option v-for="type in adsType" :key="type" :value="type">{{ $t(`adsprofile.${type}`) }}</option>
                </select>
              </div>
            </div>
            <div class="row h-100">
              <!-- Preview iphone -->
              <template
                v-if="
                  updatedAd.data.type !== 'poll' &&
                  updatedAd.data.type !== 'poll_quality' &&
                  updatedAd.data.type !== 'poll_user_data'
                "
              >
                <PreviewMobile
                  v-if="!hasDesktop || updatedAd.data.type === 'video'"
                  type="mobile"
                  :file-url="updatedAd.data.file"
                  class="m-auto"
                >
                  <template #mobile-screen>
                    <div v-if="isFileUploading && updatedAd.data.type === 'video'"  style="background-color: #000;">
                      <Loader-spinner-small centered/>
                    </div>
                    <div v-if="!isNewAd && updatedAd.data.url !== '' && (isVideosLoadingForAdIdForModal(updatedAd.id) === true ||
                    isVideosLoadingForAdIdForModal(updatedAd.id) === 'error') " class="d-flex flex-column video-in-modal-overlay" style="background-color: #000;">
                      <div
                        v-if="updatedAd.data.type === 'video' && isVideosLoadingForAdIdForModal(updatedAd.id) === true">
                        <!--                        тут выводим спиннеры на время загрузки видео-->
                        <Loader-spinner-small centered/>
                      </div>
                      <div
                        v-if="updatedAd.data.type === 'video' && isVideosLoadingForAdIdForModal(updatedAd.id) === 'error' && updatedAd.data.url !== ''">
<!--                        <H1>sdfsdsdfsdfsdsfdsdf</H1>-->
                        <!--                        а тут сообщение если видео не подгрузилось (например 404)-->
                        <div class="video-error-wrapper-in-modal">
                          <div class="video-error-container-in-modal">
                            <i class="fa fa-remove text-danger">
                              <span class="ml-h">{{ $t('adsprofile.videoLoadingError') }}</span>
                            </i>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="updatedAd.data.type === 'video' && isVideosLoadingForAdIdForModal(updatedAd.id) === 'error' && updatedAd.data.url !== ''">
                        <!--                        а тут кнопку для обновления видео если видео не подгрузилось (например 404)-->
                        <div class="video-reload-wrapper-in-modal">
                          <div class="video-reload-container-in-modal mt-1">
<!--                            <span @click="reloadVideoByAdIdForModal(updatedAd.id)" role="button">-->
<!--                                                            {{ $t('adsprofile.videoLoadingErrorReload') }}-->
<!--                            </span>-->
                            <button
                              @click.stop="reloadVideoByAdIdForModal(updatedAd.id)"
                            class="btn btn-sm btn-outline-secondary "
                          >
                            {{ $t('adsprofile.videoLoadingErrorReload') }}
                          </button>
                          </div>
                        </div>
                        <div>
                                                    <i
                            class="fa fa-remove text-danger file-upload-delete small mt-1"
                            style="font-size: 3em"
                            @click.stop.prevent="deletePhoto('mobile')"
                            data-action="delete"
                            v-if="updatedAd.data.file != false || updatedAd.data.url != false"
                          ></i>
                        </div>
                      </div>


                    </div>
                    <div class="file-upload" style="background-color: #000;">
                      <label class="file-upload my-0" for="media-mobile">
                        <div
                          v-if="updatedAd.data.type === 'image' && (updatedAd.data.file || updatedAd.data.url)"
                          class="file-upload-img preview"
                          :style="{
                            'background-image':
                              `url(${baseURL}/api/portal` +
                              (isMobileUploaded
                                ? '/static/' + updatedAd.data.file + ')'
                                : '-preview' + updatedAd.data.url + ')'),
                            'background-size': 'contain'
                          }"
                        >
                          <button
                            class="button-preview button-preview--size-full video-view__next-button-preview"
                            :style="{ backgroundColor: updatedAd.data.redirect_button_color }"
                          >
                            {{ redirectButton.text[redirectButton.currentLang] }}
                          </button>
                        </div>
                        <div
                          class="file-upload-img"
                          v-if="updatedAd.data.type === 'video' && (updatedAd.data.file || updatedAd.data.url)"
                        >
                          <video
                            v-if="updatedAd.data.type === 'video' && (updatedAd.data.file || updatedAd.data.url)"
                            :data-id="`${updatedAd.id}`"
                            ref="videoMobile"
                            autoplay
                            muted
                            class="preview loading-media-in-modal"
                            :src="
                              `${baseURL}/api/portal` +
                              (isMobileUploaded ? '/static/' + updatedAd.data.file : '-preview' + updatedAd.data.url)
                            "
                          />
                          <button
                            v-if="updatedAd.data.type === 'video' && (updatedAd.data.file || updatedAd.data.url)"
                            class="button-preview button-preview--size-full video-view__next-button-preview"
                            :style="{
                              backgroundColor: updatedAd.data.redirect_button_color,
                              position: 'absolute',
                              bottom: '30px'
                            }"
                          >
                            {{ redirectButton.text[redirectButton.currentLang] }}
                          </button>
                        </div>
                        <div class="file-upload-edit">
                          <div v-if="updatedAd.data.type === 'image'" class="img-video-recommendations-mobile">
                            {{ $t('imgAndVideoRecommendations.adsAndPollsImgMobile') }}
                          </div>
                          <div v-if="updatedAd.data.type === 'video'" class="img-video-recommendations-mobile">
                            {{ $t('imgAndVideoRecommendations.adsAndPollsVideo') }}
                          </div>
                          <i class="icon-camera file-upload-icon big"></i>
                          <i
                            class="fa fa-remove text-danger file-upload-delete small"
                            @click.stop.prevent="deletePhoto('mobile')"
                            data-action="delete"
                            v-if="updatedAd.data.file != false || updatedAd.data.url != false"
                          ></i>
                        </div>
                        <input
                          id="media-mobile"
                          name="files"
                          type="file"
                          @change="uploadMediaToServer('mobile')"
                          class="form-control file-upload-field mobile"
                          :v-validate="{ image: updatedAd.data.type === 'image' }"
                          data-vv-as="FILE"
                        />
                      </label>
                    </div>
                  </template>
                </PreviewMobile>
                <!-- Preview macbook -->

                <PreviewMobile
                  v-if="hasDesktop && updatedAd.data.type === 'image'"
                  type="laptop"
                  :file-url="updatedAd.data.file_desktop"
                  class="d-flex m-auto"
                >
                  <template #laptop-screen>
                    <div class="file-upload" style="background-color: #000;">
                      <label class="file-upload my-0" for="media-desktop">
                        <div
                          v-if="
                            updatedAd.data.type === 'image' &&
                            (updatedAd.data.file_desktop || updatedAd.data.url_desktop)
                          "
                          class="file-upload-img preview"
                          :style="{
                            backgroundImage:
                              `url(${baseURL}/api/portal` +
                              (isDesktopUploaded
                                ? '/static/' + updatedAd.data.file_desktop + ')'
                                : '-preview' + updatedAd.data.url_desktop + ')')
                          }"
                        >
                          <button
                            class="button-preview button-preview--size-full video-view__next-button-preview"
                            :style="{
                              backgroundColor: updatedAd.data.redirect_button_color,
                              bottom: '10%',
                              'min-height': '20px'
                            }"
                          >
                            {{ redirectButton.text[redirectButton.currentLang] }}
                          </button>
                        </div>
                        <div class="file-upload-edit">
                          <div v-if="updatedAd.data.type === 'image'" class="img-video-recommendations-desktop">
                            {{ $t('imgAndVideoRecommendations.adsAndPollsImgDesktop') }}
                          </div>
                          <i class="icon-camera file-upload-icon big"></i>
                          <i
                            class="fa fa-remove text-danger file-upload-delete small"
                            @click.stop.prevent="deletePhoto('desktop')"
                            data-action="delete"
                            v-if="updatedAd.data.file_desktop != false || updatedAd.data.url_desktop != false"
                          ></i>
                        </div>
                        <input
                          id="media-desktop"
                          name="files"
                          type="file"
                          @change="uploadMediaToServer('desktop')"
                          class="form-control file-upload-field desktop"
                        />
                      </label>
                    </div>
                  </template>
                </PreviewMobile>

                <i
                  v-if="updatedAd.data.type === 'video' &&
                  !isNewAd &&
                  isVideosLoadingForAdIdForModal(updatedAd.id) !== true &&
                  isVideosLoadingForAdIdForModal(updatedAd.id) !== 'error'"
                  style="position: absolute; right: 0;"
                  class="fa fa-2x fa-fw"
                  :class="{ 'fa-volume-up': !isVideoMuted, 'fa-volume-off': isVideoMuted }"
                  role="button"
                  @click="toggleVideoSound"
                ></i>
                <span v-show="errors.has('files')" class="help is-danger">
                  {{ errors.first('files') }}
                </span>
              </template>
              <template
                v-if="
                  updatedAd.data.type === 'poll' ||
                  updatedAd.data.type === 'poll_quality' ||
                  updatedAd.data.type === 'poll_user_data'
                "
              >
                <PreviewMobile type="mobile" :is-scrollable="true" class="m-auto">
                  <template #mobile-screen>
                    <poll-preview
                      :key="updatedAd.id"
                      :type="updatedAd.data.type"
                      :ad="updatedAd"
                      :theme-color="updatedAd.data.theme_color"
                      :theme-type="updatedAd.data.theme_type"
                      class="overflowX-hidden no-scroll-bar"
                    />
                  </template>
                </PreviewMobile>
              </template>
            </div>
          </div>
          <!-- right side -->
          <div class="col-5">
            <!-- Name -->
            <div class="form-group">
              <label for="name" class="required-mark">{{ $t('general.name') }}</label>
              <input
                id="name"
                v-model="updatedAd.name"
                v-validate="'required|max:32'"
                type="text"
                name="name"
                :placeholder="$t('general.name')"
                :readonly="isDisable"
                class="form-control true"
                :class="{ 'is-danger': errors.has('name') }"
              />
              <span
                v-show="errors.has('name')"
                v-tooltip.top-center="{
                  content: errors.first('name')
                }"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>

            <!-- Description -->
            <div class="form-group">
              <label for="description">{{ $t('general.description') }}</label>
              <input
                id="description"
                v-model="updatedAd.description"
                v-validate="'max:64'"
                type="text"
                name="description"
                :placeholder="$t('general.description')"
                :readonly="isDisable"
                class="form-control true"
                :class="{ 'is-danger': errors.has('description') }"
              />
              <span
                v-show="errors.has('description')"
                v-tooltip.top-center="{
                  content: errors.first('description')
                }"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <!-- Location -->
            <div class="form-group">
              <label for="location">{{ $t('general.location') }}</label>
              <select v-model="updatedAd.base_location" id="location" name="location" class="form-control">
                <option v-for="location in locationsList" :key="location.id" :value="location.id">
                  {{ location.name }}
                </option>
              </select>
            </div>
            <br />
            <br />

            <div class="form-group">
              <label for="schedule" class="required-mark">{{ $t('adsprofile.schedule') }}</label>
              <div>
                <date-picker
                  id="schedule"
                  v-if="!isDisable && isAdsModalOpen"
                  v-model="updatedAd.schedule"
                  v-on:input="datePickerChangedDateinModal"
                  input-class="mx-input text-left"
                  type="date"
                />
              </div>
              <small id="schedule-caption" class="text-muted">{{ $t('adsprofile.scheduleCaption') }}</small>
              <span
                v-show="errors.has('schedule')"
                v-tooltip.top-center="{
                  content: errors.first('schedule')
                }"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>

            <!-- Priority -->
            <div class="form-group">
              <label for="priority" class="required-mark">{{ $t('adsprofile.priority') }}</label>
              <input
                id="priority"
                v-model.number="updatedAd.priority"
                v-validate="'required|between:0,100|integer'"
                type="number"
                name="priority"
                :placeholder="$t('adsprofile.priority')"
                class="form-control true"
                aria-describedby="priority-caption"
                :disabled="isDisable"
                :class="{ 'is-danger': errors.has('priority') }"
              />
              <small id="priority-caption" class="text-muted">{{ $t('adsprofile.priorityCaption') }}</small>
              <span
                v-show="errors.has('priority')"
                v-tooltip.top-center="{
                  content: errors.first('priority')
                }"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <!-- Views -->
            <div class="form-group">
              <label for="views" class="required-mark">{{ $t('adsprofile.views') }}</label>
              <input
                id="views"
                v-model.number="updatedAd.schedule.views"
                v-validate="'required|min_value:0|integer'"
                type="number"
                name="views"
                :placeholder="$t('adsprofile.views')"
                class="form-control true"
                aria-describedby="views-caption"
                :disabled="isDisable"
                :class="{ 'is-danger': errors.has('views') }"
              />
              <small id="views-caption" class="text-muted">{{ $t('adsprofile.viewsCaption') }}</small>

              <span
                v-show="errors.has('views')"
                v-tooltip.top-center="{
                  content: errors.first('views')
                }"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <br />
            <br />
            <template
              v-if="
                updatedAd.data.type !== 'poll' &&
                updatedAd.data.type !== 'poll_quality' &&
                updatedAd.data.type !== 'poll_user_data'
              "
            >
              <!-- Ad duration -->
              <div class="form-group">
                <label for="duration" class="required-mark">{{ $t('adsprofile.duration') }}</label>
                <input
                  id="duration"
                  v-model.number="updatedAd.data.duration"
                  v-validate="'required|numeric|integer'"
                  type="number"
                  name="duration"
                  :placeholder="$t('adsprofile.skipafter')"
                  class="form-control true"
                  :disabled="isDisable"
                  :class="{ 'is-danger': errors.has('duration') }"
                />
                <span
                  v-show="errors.has('duration')"
                  v-tooltip.top-center="{
                    content: errors.first('duration')
                  }"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error" />
                </span>
              </div>

              <!-- Skip ad after -->
              <div class="row">
                <div class="form-group col-lg-9 col-md-12 col-9">
                  <Switch-component
                    v-model="updatedAd.data.skip"
                    :disabled="isDisable"
                    :label="$t('adsprofile.skipafter')"
                    id="skip-after"
                  />
                </div>
                <div class="form-group col-lg-3 col-md-12 col-3">
                  <input
                    id="skip_after"
                    v-model.number="updatedAd.data.skip_after"
                    v-validate="'numeric|integer'"
                    type="number"
                    name="skip_after"
                    :placeholder="$t('adsprofile.skipafter')"
                    class="form-control true"
                    :disabled="!updatedAd.data.skip || isDisable"
                    :class="{ 'is-danger': errors.has('skip_after') }"
                  />
                  <span
                    v-show="errors.has('skip_after')"
                    v-tooltip.top-center="{ content: errors.first('skip_after') }"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error" />
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex flex-column">
                  <div class="btn-group float-left" data-toggle="button">
                    <label class="btn btn-outline-primary" :class="{ active: redirectButton.currentLang === 'ru' }">
                      <input type="radio" name="socialAuth" @click="redirectButton.currentLang = 'ru'" />
                      {{ $t('portalStats.locale.ru') }}
                    </label>
                    <label class="btn btn-outline-primary" :class="{ active: redirectButton.currentLang === 'en' }">
                      <input type="radio" name="socialAuth" @click="redirectButton.currentLang = 'en'" />
                      {{ $t('portalStats.locale.en') }}
                    </label>
                    <label class="btn btn-outline-primary" :class="{ active: redirectButton.currentLang === 'tg' }">
                      <input type="radio" name="socialAuth" @click="redirectButton.currentLang = 'tg'" />
                      {{ $t('portalStats.locale.tg') }}
                    </label>
                  </div>
                  <label for="close-button-text">{{ $t('adsprofile.enterRedirectButtonText') }}</label>
                </div>
                <div class="row col-12 justify-content-between">
                  <input
                    :class="{ 'is-danger': errors.has('close-button-text') }"
                    :disabled="isDisable"
                    class="form-control form-group input col-10 mt-q"
                    id="close-button-text"
                    name="close-button-text"
                    :placeholder="$t('adsprofile.enterRedirectButtonTextPlaceholder')"
                    v-model="redirectButton.text[redirectButton.currentLang]"
                    @change="showInfoMsgWhenRedirectButtonColorChanged"
                  />
                  <span
                    v-tooltip.top-center="{ content: errors.first('close-button-text') }"
                    v-show="errors.has('close-button-text')"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error" />
                  </span>
                  <color-palette
                    @set-color="showInfoMsgWhenRedirectButtonColorChanged"
                    :default-value="updatedAd.data.redirect_button_color"
                    :button-text="$t('drPicker.apply')"
                    :no-color-text="$t('adsprofile.noColor')"
                    position="left"
                    :colors="COLORS"
                    v-model="updatedAd.data.redirect_button_color"
                  />
                </div>
                <Alert
                  v-show="!isNewAd && isInfoMsgWhenRedirectButtonColorChangedShowing"
                  :closable="true"
                  :text="$t('adsprofile.changeRedirectButtonColorAlert')"
                  class="alert-warning"
                ></Alert>
              </div>
              <!-- Redirect -->
              <div class="form-group">
                <label for="redirect_url">{{ $t('adsprofile.redirectURL') }}</label>
                <input
                  id="redirect_url"
                  v-model.trim="updatedAd.data.redirect_url"
                  v-validate="'url'"
                  type="text"
                  name="redirect_url"
                  placeholder="https://www.example.com/you-should-see/this-thing.html"
                  aria-describedby="redirect-url-caption"
                  class="input form-control"
                  :disabled="isDisable"
                />
                <span
                  v-show="errors.has('redirect_url')"
                  v-tooltip.top-center="{
                    content: errors.first('redirect_url')
                  }"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error" />
                </span>
                <small id="redirect-url-caption" class="text-muted">{{ $t('adsprofile.redirectURLCaption') }}</small>
              </div>
            </template>
            <!-- Poll -->
            <template v-if="updatedAd.data.type === 'poll'">
              <div class="form-group">
                <label for="question" class="required-mark">{{ $t('adsprofile.question') }}</label>
                <input
                  id="question"
                  v-model="updatedAd.data.question"
                  v-validate="'required'"
                  type="text"
                  name="question"
                  data-vv-name="question"
                  :placeholder="$t('adsprofile.question')"
                  class="form-control true"
                  :disabled="isDisable"
                  :class="{ 'is-danger': errors.has('question') }"
                />
                <span
                  v-show="errors.has('question')"
                  v-tooltip.top-center="{
                    content: errors.first('question')
                  }"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error" />
                </span>
              </div>
              <div v-if="updatedAd.data.type === 'poll'" class="form-group">
                <div class="form-group mt-2 mb-q">
                  <Switch-component
                    v-model="updatedAd.data.self_variant"
                    :disabled="isDisable"
                    :label="$t('adsprofile.self_variant')"
                    id="self_variant"
                  />
                </div>
              </div>

              <p class="mb-q">{{ $t('adsprofile.pollvariants') }}</p>

              <div class="btn-group mb-1" role="group">
                <button
                  type="button"
                  class="btn"
                  :class="[hasPollPictures === false ? 'btn-primary' : 'btn-outline-primary']"
                  @click="handlePollSwitch(false)"
                >
                  {{ $t('adsprofile.textOnly') }}
                </button>

                <button
                  type="button"
                  class="btn"
                  :class="[hasPollPictures === true ? 'btn-primary' : 'btn-outline-primary']"
                  @click="handlePollSwitch(true)"
                >
                  {{ $t('adsprofile.withImages') }}
                </button>
              </div>

              <poll-images v-if="hasPollPictures" v-model="updatedAd.data.poll_image" />

              <Add-list
                v-if="!hasPollPictures"
                :list="updatedAd.data.poll_variants"
                :placeholder="$t('adsprofile.addanswer')"
                :is-disable="isDisable || updatedAd.data.poll_variants.length > 4"
                validate="pollquestion"
                data-vv-name="poll-variants"
                type-list="array"
              />

              <div class="form-group mt-2 mb-q">
                <Switch-component
                  v-model="hasTheme"
                  :disabled="isDisable"
                  :label="$t('adsprofile.hasTheme')"
                  id="has-theme"
                />
              </div>
              <div class="m-auto">
                <portal-palette
                  v-if="hasTheme"
                  :selected-color="updatedAd.data.theme_color"
                  :selected-type="updatedAd.data.theme_type"
                  @select-color="handleSelectColor"
                  @select-type="handleSelectType"
                />
              </div>
              <Alert
                v-show="!isNewAd && isInfoMsgWhenPollsColorThemeChangedShowing && hasTheme"
                :closable="true"
                :text="$t('adsprofile.changePollsColorThemeAlert')"
                class="alert-warning"
              ></Alert>
            </template>
            <!-- Poll  Quality-->
            <template v-if="updatedAd.data.type === 'poll_quality'">
              <div class="form-group">
                <label for="question" class="required-mark">{{ $t('adsprofile.question') }}</label>
                <input
                  id="question"
                  v-model="updatedAd.data.question"
                  v-validate="'required'"
                  type="text"
                  name="duration"
                  :placeholder="$t('adsprofile.question')"
                  class="form-control true"
                  :disabled="isDisable"
                  :class="{ 'is-danger': errors.has('question') }"
                />
                <span
                  v-show="errors.has('question')"
                  v-tooltip.top-center="{
                    content: errors.first('question')
                  }"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error" />
                </span>
              </div>
              <Poll-quality-variants
                v-if="isAdsModalOpen"
                :list="updatedAd.data.poll_variants"
                :placeholder="$t('adsprofile.addanswer')"
                :is-disable="isDisable"
                validate="pollquestion"
                type-list="array"
              ></Poll-quality-variants>

              <div class="form-group mt-2 mb-q">
                <Switch-component
                  v-model="hasTheme"
                  :disabled="isDisable"
                  :label="$t('adsprofile.hasTheme')"
                  id="has-theme"
                />
              </div>
              <div class="m-auto">
                <portal-palette
                  v-if="hasTheme"
                  :selected-color="updatedAd.data.theme_color"
                  :selected-type="updatedAd.data.theme_type"
                  @select-color="handleSelectColor"
                  @select-type="handleSelectType"
                />
              </div>
              <Alert
                v-show="!isNewAd && isInfoMsgWhenPollsColorThemeChangedShowing && hasTheme"
                :closable="true"
                :text="$t('adsprofile.changePollsColorThemeAlert')"
                class="alert-warning"
              ></Alert>
            </template>
            <!-- Poll  User data-->
            <template v-if="updatedAd.data.type === 'poll_user_data'">
              <div class="form-group">
                <div class="form-group mt-2 mb-q">
                  <Switch-component
                    v-model="updatedAd.data.poll_fields.date_of_birth"
                    :disabled="isDisable"
                    :label="$t('adsprofile.enableBirthDate')"
                    id="date-of-birthday"
                  />
                </div>
                <div class="form-group mt-2 mb-q">
                  <Switch-component
                    v-model="updatedAd.data.poll_fields.sex"
                    :disabled="isDisable"
                    :label="$t('adsprofile.enableSex')"
                    id="sex"
                  />
                </div>
              </div>
              <div class="form-group mt-2 mb-q">
                <Switch-component
                  v-model="hasTheme"
                  :disabled="isDisable"
                  :label="$t('adsprofile.hasTheme')"
                  id="has-theme"
                />
              </div>
              <div class="m-auto">
                <Portal-palette
                  v-if="hasTheme"
                  :selected-color="updatedAd.data.theme_color"
                  :selected-type="updatedAd.data.theme_type"
                  @select-color="handleSelectColor"
                  @select-type="handleSelectType"
                />
              </div>
              <Alert
                v-show="!isNewAd && isInfoMsgWhenPollsColorThemeChangedShowing && hasTheme"
                :closable="true"
                :text="$t('adsprofile.changePollsColorThemeAlert')"
                class="alert-warning"
              ></Alert>
            </template>
          </div>
          <div class="col-12">
            <div class="mt-1 form-group">
              <div class="row align-items-baseline">
                <div class="col-7 h5" @click="toggleTargetingSettings" role="button">
                  <i v-if="targetingSettingsShow" class="icon-arrow-down"></i>
                  <i v-else class="icon-arrow-right"></i>
                  {{ $t('adsprofile.targeting') }}
                </div>
                <h6 class="col-2 text-right" for="target">
                  {{ $t('adsprofile.sameAs') }}
                </h6>
                <div class="col-3">
                  <div class="col-12">
                    <select @input="selectInSameAs" id="target" v-model="updatedAd" class="form-control">
                      <option
                        v-for="option in portalAdsWithStatsFiltered"
                        :key="option.id"
                        :value="{
                          ...updatedAd,
                          loc_ids: option.loc_ids,
                          os: option.os,
                          platform: option.platform,
                          vendor: option.vendor,
                          number_of_visits: option.number_of_visits,
                          schedule: { ...option.schedule, views: updatedAd.schedule.views }
                        }"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="targetingSettingsShow">
              <div class="col-12 form-group">
                <h6 for="locids">{{ $t('general.locations') }}</h6>
                <Multi-select-component
                  :in-modal="true"
                  v-model="updatedAdlocIds"
                  class="w-100"
                  option-id-name="id"
                  track-by="name"
                  :placeholder="$t('general.locations')"
                  :async-function="requestLocations.bind(this)"
                />
              </div>
              <div class="col-12 form-group">
                <h6>{{ $t('adsprofile.dayOfWeek') }}</h6>
                <!-- Days of week-->
                <div class="cool-modern-selector">
                  <template v-for="day of DAYS_OF_WEEK">
                    <input
                      :key="day"
                      class="form-check-input mr-1"
                      type="checkbox"
                      :id="`dayOfWeek${day}`"
                      v-model="updatedAd.schedule.day_of_week[day.toLowerCase()]"
                    />
                    <label class="form-check-label" :for="`dayOfWeek${day}`" :key="`label-${day}`">
                      {{ $t(`adsprofile.dayOfWeek${day}`) }}
                    </label>
                  </template>
                </div>
                <div>
                  <small class="text-muted">{{ $t('adsprofile.dayOfWeekCaption') }}</small>
                </div>
              </div>

              <div class="col-12 form-group">
                <h6>{{ $t('adsprofile.timeOfDay') }}</h6>
                <!-- Time of day-->

                <div class="cool-modern-selector">
                  <template v-for="time of TIME_OF_DAY">
                    <input
                      :key="time"
                      class="form-check-input mr-1"
                      type="checkbox"
                      :id="`timeOfDay${time}`"
                      v-model="updatedAd.schedule.time_of_day[time.toLowerCase()]"
                    />
                    <label class="form-check-label" :for="`timeOfDay${time}`" :key="`label-${time}`">
                      {{ $t(`adsprofile.timeOfDay${time}`) }}
                    </label>
                  </template>
                </div>
                <div>
                  <small class="text-muted">{{ $t('adsprofile.timeOfDayCaption') }}</small>
                </div>
              </div>
              <div class="col-12 form-group">
                <!--Os-->
                <div class="os-list">
                  <AdOSChoice
                    v-if="isAdsModalOpen"
                    :list="updatedAd.os"
                    :is-disable="isDisable"
                    type-list="array"
                    :advice="$t('adsprofile.osInstruction')"
                  ></AdOSChoice>
                </div>
              </div>
              <div class="col-12 form-group">
                <!--Vendors-->
                <div class="vendors">
                  <Ad-vendor-choice
                    v-if="isAdsModalOpen"
                    :list="updatedAd.vendor"
                    :is-disable="isDisable"
                    type-list="array"
                    :advice="$t('adsprofile.vendorinstruction')"
                  ></Ad-vendor-choice>
                </div>
              </div>
              <div class="col-12 form-group">
                <!-- platform -->
                <h6>{{ $t('adsprofile.platform') }}</h6>

                <div class="cool-modern-selector">
                  <template v-for="type of PLATFORM">
                    <input
                      :key="type"
                      class="form-check-input mr-1"
                      type="checkbox"
                      :id="`platform${type}`"
                      v-model="updatedAd.platform[type.toLowerCase()]"
                    />
                    <label class="form-check-label" :for="`platform${type}`" :key="`label-${type}`">
                      {{ $t(`adsprofile.platform${type}`) }}
                    </label>
                  </template>
                </div>
                <div>
                  <small class="text-muted">{{ $t('adsprofile.platformCaption') }}</small>
                </div>
              </div>
              <!--number of visits-->
              <div class="col-12 form-group">
                <div>
                  <h6>{{ $t('adsprofile.numberOfVisitsHeader') }}</h6>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="number_of_visits_sign" class="required-mark">
                      {{ $t('adsprofile.numberOfVisitsSign') }}
                    </label>
                    <select
                      id="number_of_visits_sign"
                      v-model="updatedAd.number_of_visits.sign"
                      :disabled="isDisable"
                      class="form-control"
                    >
                      <option v-for="type in numberOfVisitsSignType" :key="type" :value="type">
                        {{ $t(`adsprofile.numberOfVisitsSign${type}`) }}
                      </option>
                    </select>
                  </div>
                  <div class="col-6">
                    <label for="number_of_visits" class="required-mark">
                      {{ $t('adsprofile.numberOfVisits') }}
                    </label>
                    <input
                      id="number_of_visits"
                      v-model.number="updatedAd.number_of_visits.visits"
                      v-validate="'required|min_value:0|integer'"
                      data-vv-validate-on="input"
                      type="number"
                      name="number_of_visits"
                      :placeholder="$t('adsprofile.numberOfVisits')"
                      class="form-control true"
                      aria-describedby="priority-caption"
                      :disabled="isDisable"
                      :class="{ 'is-danger': errors.has('priority') }"
                    />
                    <span
                      v-show="errors.has('number_of_visits')"
                      v-tooltip.top-center="{
                        content: errors.first('number_of_visits')
                      }"
                      class="help is-danger error_tooltip"
                    >
                      <i class="fa fa-warning text-error" />
                    </span>
                  </div>
                </div>
                <small class="text-muted">{{ $t('adsprofile.numberOfVisitsCaption') }}</small>
              </div>
            </template>
          </div>
        </div>

        <!-- Modal footer -->
        <div slot="modal-footer" class="modal-footer justify-content-between">
          <button
            v-if="!isNewAd && !isDisable"
            type="button"
            class="btn btn-outline-success mr-1"
            :disabled="errors.any() || muteEdit"
            @click="updateAd(updatedAd)"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini" />
          </button>

          <button
            v-else-if="!isDisable"
            type="button"
            class="btn btn-outline-success mr-1"
            :disabled="muteEdit || errors.any()"
            @click="createAd(updatedAd)"
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.create') }}</span>
            <span v-if="muteEdit" class="loader loader--mini" />
          </button>

          <div>
            <button
              v-if="!isNewAd && !isDisable"
              type="button"
              class="btn btn-outline-danger mr-2"
              @click="isDeleteOpen = true"
            >
              {{ $t('general.delete') }}
            </button>
            <button type="button" class="btn btn-outline-secondary" @click="cancelEditMode">
              {{ $t('general.close') }}
            </button>
          </div>
        </div>
      </Modal>
      <Delete-dialog
        :is-open="isDeleteOpen"
        :handle-close="handleCloseDeleteDialog.bind(this)"
        :handle-delete="deleteAd.bind(this)"
        :target-text="updatedAd.name"
      >
        <template v-slot:additional>
          <WarningLine class="mt-1 mb-0">
            <i18n path="adsprofile.warnUsage" tag="span" for="adsprofile.warnUsageAccent">
              <span class="font-weight-bold text-danger">{{ $t('adsprofile.warnUsageAccent') }}</span>
            </i18n>
          </WarningLine>
        </template>
      </Delete-dialog>

      <Close-dialog
        :is-open="isCloseModalOpen"
        :handle-yes="handleConfirmClose.bind(this)"
        :handle-no="closeConfirmModal.bind(this)"
      />

      <Modal v-model="isPollWarnOpen" title="Poll Warning" class="modal-warning" effect="fade/zoom">
        <h4 slot="modal-title" class="modal-title">{{ $t('general.warning') }}</h4>
        <span>{{ $t('adsprofile.switchPollWarning') }}</span>
        <div slot="modal-footer" class="modal-footer">
          <button type="button" class="btn btn-secondary ml-auto" @click="handlePollModalConfirm">
            {{ $t('general.yes') }}
          </button>
          <button type="button" class="btn btn-secondary ml-1" @click="handlePollModalCancel">
            {{ $t('general.no') }}
          </button>
        </div>
      </Modal>
      <Modal
        v-model="isShowsStatsModalOpen"
        class="modal-info"
        effect="fade/zoom"
        @cancel="handleShowsStatsModalClose"
        closeBtn
        xlarge
      >
        <h4 slot="modal-title" class="modal-title">
          <span>{{ $t('adsprofile.pollResults') }}</span>
        </h4>
        <div
          v-if="currentAdStats.statistics && currentAdStats.statisticsPoll && currentAdStats.statisticsPoll.length"
          class="row"
        >
          <div v-if="currentAdStats.type === 'poll' || currentAdStats.type === 'poll_quality'" class="col-4">
            <h4>{{ currentAdStats.data.question }}</h4>
            <h1 v-if="currentAdStats.type === 'poll' || currentAdStats.type === 'poll_quality'" class="display-3">
              <i>
                {{
                  toPercent(
                    currentAdStats.statisticsPoll[0][1],
                    Object.values(currentAdStats.statistics.poll_counter).reduce((prev, curr) => (prev += curr), 0)
                  )
                }}
                <small>%</small>
              </i>
            </h1>
            <h3 v-if="currentAdStats.type === 'poll'">
              {{ currentAdStats.statisticsPoll[0][0] }}
            </h3>
            <span v-if="currentAdStats.type === 'poll_quality'">
              <span v-for="star of +currentAdStats.statisticsPoll[0][0]" class="star-for-poll-quality-in-table h1">
                ★
              </span>
            </span>
          </div>
          <div class="col-8 m-0 alert alert-info text-left">
            <h5
              v-if="currentAdStats.type === 'poll'"
              v-for="item in currentAdStats.statisticsPoll"
              :key="item[0]"
              class="mb-q"
            >
              <span>
                <i>
                  {{
                    toPercent(
                      item[1],
                      Object.values(currentAdStats.statistics.poll_counter).reduce((prev, curr) => (prev += curr), 0)
                    )
                  }}
                  <small>%</small>
                </i>
              </span>
              <span>&nbsp;({{ item[1] }})&nbsp;—&nbsp;</span>
              <span>{{ item[0] }}</span>
            </h5>
            <div v-if="currentAdStats.type === 'poll_quality'" class="mb-q ml-q">
              <poll-quality-stars :ad="currentAdStats" />
            </div>
          </div>
        </div>
        <div v-else>{{ $t('aps.noDataToDisplay') }}</div>

        <div slot="modal-footer" class="modal-footer">
          <button type="button" class="btn btn-secondary ml-auto" @click="handleShowsStatsModalClose">
            {{ $t('general.close') }}
          </button>
        </div>
      </Modal>

      <!--      Mass schedule edit-->
      <Modal
        v-model="massAdsEditScheduleModalShowing"
        class="modal-info modal-info-in-ads"
        large
        effect="fade/zoom"
        :backdrop="false"
        closeBtn
      >
        <!-- Modal header -->
        <h4 slot="modal-title" class="modal-title">
          <span>{{ $t('adsprofile.massAdsEditSchedule') }}</span>
        </h4>

        <!-- Modal body -->
        <div class="d-flex flex-column" v-if="massAdsEditScheduleModalShowing">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-start">
                <div class="form-group mb-0 mt-0">
                  <label for="scheduleMassAdsEdit">{{ $t('adsprofile.schedule') }}</label>
                  <div>
                    <Date-picker
                      id="scheduleMassAdsEdit"
                      v-model="massUpdatedAd.schedule"
                      type="date"
                      input-class="mx-input form-control"
                    />
                  </div>
                  <small id="scheduleMassAdsEdit-caption" class="text-muted">
                    {{ $t('adsprofile.scheduleCaption') }}
                  </small>
                  <span
                    v-show="errors.has('scheduleMassAdsEdit')"
                    v-tooltip.top-center="{
                      content: errors.first('scheduleMassAdsEdit')
                    }"
                    class="help is-danger error_tooltip"
                  >
                    <i class="fa fa-warning text-error" />
                  </span>
                  <p></p>
                </div>
              </div>
            </div>
            <!-- </transition> -->
          </div>
        </div>

        <!-- Modal footer -->
        <div slot="modal-footer" class="modal-footer justify-content-between">
          <button type="button" class="btn btn-outline-success mr-1" @click="massUpdateAdsSchedule">
            <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini" />
          </button>

          <div>
            <button type="button" class="btn btn-outline-secondary" @click="massAdsEditScheduleCloseModal">
              {{ $t('general.close') }}
            </button>
          </div>
        </div>
      </Modal>
      <!--      Mass targeting edit-->
      <Modal
        v-model="massAdsEditTargetingModalShowing"
        class="modal-info modal-info-in-ads"
        large
        effect="fade/zoom"
        :backdrop="false"
        closeBtn
      >
        <!-- Modal header -->
        <h4 slot="modal-title" class="modal-title">
          <span>{{ $t('adsprofile.massAdsEditTargeting') }}</span>
        </h4>

        <!-- Modal body -->
        <div class="d-flex flex-column" v-if="massAdsEditTargetingModalShowing">
          <div class="row">
            <div class="col-12">
              <div v-if="massAdsEditTargetingModalShowing">
                <div class="col-12 form-group">
                  <h6 for="locids">{{ $t('general.locations') }}</h6>
                  <Multi-select-component
                    :in-modal="true"
                    class="w-100"
                    option-id-name="id"
                    track-by="name"
                    :disabled="isDisable"
                    :placeholder="$t('general.locations')"
                    :async-function="requestLocations.bind(this)"
                    v-model="massUpdateAdlocIds"
                  />
                </div>
                <div class="col-12 days-of-week form-group">
                  <h6>{{ $t('adsprofile.dayOfWeek') }}</h6>
                  <!-- Days of week-->
                  <div class="cool-modern-selector">
                    <template v-for="day of DAYS_OF_WEEK">
                      <input
                        class="form-check-input mr-1"
                        type="checkbox"
                        :key="day"
                        :id="`dayOfWeek${day}MassAdsEdit`"
                        v-model="massUpdatedAd.schedule.day_of_week[day.toLowerCase()]"
                      />
                      <label class="form-check-label" :for="`dayOfWeek${day}MassAdsEdit`" :key="`label-${day}`">
                        {{ $t(`adsprofile.dayOfWeek${day}`) }}
                      </label>
                    </template>
                  </div>
                  <div>
                    <small class="text-muted">{{ $t('adsprofile.dayOfWeekCaption') }}</small>
                  </div>
                </div>

                <div class="col-12 time-of-day form-group">
                  <h6>{{ $t('adsprofile.timeOfDay') }}</h6>
                  <!-- Time of day-->
                  <div class="cool-modern-selector">
                    <template v-for="time of TIME_OF_DAY">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`timeOfDay${time}MassAdsEdit`"
                        :key="time"
                        v-model="massUpdatedAd.schedule.time_of_day[time.toLowerCase()]"
                      />
                      <label class="form-check-label" :for="`timeOfDay${time}MassAdsEdit`" :key="`label-${time}`">
                        {{ $t(`adsprofile.timeOfDay${time}`) }}
                      </label>
                    </template>
                  </div>
                  <div>
                    <small class="text-muted">{{ $t('adsprofile.timeOfDayCaption') }}</small>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <!--Os-->
                  <div class="os-list">
                    <AdOSChoice
                      v-if="massAdsEditTargetingModalShowing"
                      :list="massUpdatedAd.os"
                      :is-disable="isDisable"
                      type-list="array"
                      :advice="$t('adsprofile.osInstruction')"
                    ></AdOSChoice>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <!--Vendors-->
                  <div class="vendors">
                    <Ad-vendor-choice
                      v-if="massAdsEditTargetingModalShowing"
                      :list="massUpdatedAd.vendor"
                      :is-disable="isDisable"
                      type-list="array"
                      :advice="$t('adsprofile.vendorinstruction')"
                    ></Ad-vendor-choice>
                  </div>
                </div>
                <div class="col-12 form-group">
                  <!-- platform -->
                  <div class="platform">
                    <h6>{{ $t('adsprofile.platform') }}</h6>
                    <div class="cool-modern-selector">
                      <template v-for="platform of PLATFORM">
                        <input
                          :key="platform"
                          class="form-check-input"
                          type="checkbox"
                          :id="`platform${platform}MassAdsEdit`"
                          v-model="massUpdatedAd.platform[platform.toLowerCase()]"
                        />
                        <label
                          class="form-check-label"
                          :for="`platform${platform}MassAdsEdit`"
                          :key="`label-${platform}`"
                        >
                          {{ $t(`adsprofile.platform${platform}`) }}
                        </label>
                      </template>
                    </div>
                    <div>
                      <small class="text-muted">{{ $t('adsprofile.platformCaption') }}</small>
                    </div>
                  </div>
                </div>
                <!--number of visits-->
                <div class="col-12 form-group">
                  <div>
                    <h6>{{ $t('adsprofile.numberOfVisitsHeader') }}</h6>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label for="number_of_visits_signMassAdsEdit" class="required-mark">
                        {{ $t('adsprofile.numberOfVisitsSign') }}
                      </label>
                      <select
                        id="number_of_visits_signMassAdsEdit"
                        v-model="massUpdatedAd.number_of_visits.sign"
                        :disabled="isDisable"
                        class="form-control"
                      >
                        <option v-for="type in numberOfVisitsSignType" :key="type" :value="type">
                          {{ $t(`adsprofile.numberOfVisitsSign${type}`) }}
                        </option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label for="number_of_visitsMassAdsEdit" class="required-mark">
                        {{ $t('adsprofile.numberOfVisits') }}
                      </label>
                      <input
                        id="number_of_visitsMassAdsEdit"
                        v-model.number="massUpdatedAd.number_of_visits.visits"
                        v-validate="'required|min_value:0|integer'"
                        data-vv-validate-on="input"
                        type="number"
                        name="number_of_visitsMassAdsEdit"
                        :placeholder="$t('adsprofile.numberOfVisits')"
                        class="form-control true"
                        aria-describedby="priority-caption"
                        :disabled="isDisable"
                        :class="{ 'is-danger': errors.has('priority') }"
                      />
                      <span
                        v-show="errors.has('number_of_visitsMassAdsEdit')"
                        v-tooltip.top-center="{
                          content: errors.first('number_of_visitsMassAdsEdit')
                        }"
                        class="help is-danger error_tooltip"
                      >
                        <i class="fa fa-warning text-error" />
                      </span>
                    </div>
                  </div>
                  <small class="text-muted">{{ $t('adsprofile.numberOfVisitsCaption') }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div slot="modal-footer" class="modal-footer justify-content-between">
          <button type="button" class="btn btn-outline-success mr-1" @click="massUpdateAdsTargeting">
            <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini" />
          </button>

          <div>
            <button type="button" class="btn btn-outline-secondary" @click="massAdsEditTargetingCloseModal">
              {{ $t('general.close') }}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Alert from '@/components/Universal/alert/alert.vue';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import AddList from '../../components/UI/AddList.vue';
import AdOSChoice from '../../components/UI/AdOSChoice.vue';
import AdsStats from '../../components/Portal/AdsStats.vue';
import AdVendorChoice from '../../components/UI/AdVendorChoice.vue';
import CloseDialog from '../../components/close-dialog.vue';
import DatePicker from '../../components/date-picker.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import DataIteratorCard from '../../components/Universal/data-iterator-card.vue';
import getAdStatus from '../../helpers/getAdStatus';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';
import PollPreview from '../../components/Portal/poll-preview.vue';
import PollImages from '../../components/Portal/poll-images.vue';
import PollQualityStars from '../../components/UI/PollQualityStars.vue';
import PollQualityVariants from '../../components/UI/PollQualityVariants.vue';
import portalAdsService from '../../services/portalAdsService';
import locationService from '../../services/locationService';
import PortalPalette from '../../components/Portal/portal-palette.vue';
import PreviewMobile from '../../components/UI/PreviewMobile.vue';
// import TablePaginationV2 from '../../components/table-pagination-v2.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import WarningLine from '../../components/warning-line.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import ColorPalette from '../../components/Universal/color-palette.vue';
import commonService from '../../services/commonService';

const watcher = new helpers.ChangesWatcher();

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const TIME_OF_DAY = ['Morning', 'Afternoon', 'Evening', 'Night'];
const PLATFORM = ['Desktop', 'Mobile', 'Tablet'];

export default {
  name: 'PortalAdsNew',
  components: {
    AddList,
    AdOSChoice,
    AdsStats,
    AdVendorChoice,
    Alert,
    CloseDialog,
    DataIteratorCard,
    DatePicker,
    DeleteDialog,
    Modal,
    MultiSelectComponent,
    PollImages,
    PollPreview,
    PollQualityStars,
    PollQualityVariants,
    PortalPalette,
    PreviewMobile,
    // TablePaginationV2,
    WarningLine,
    CheckboxComponent,
    SwitchComponent,
    ColorPalette,
    TablePaginationV3
  },
  inject: ['$validator'],
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    maxDigits(value) {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
    }
  },
  data() {
    return {
      showTablePaginationV3Component: true,
      isFileUploading: false, // когда загружается файл переводится в true чтобы показывать спинер
      intervalForCheckVideo: false, // тут храним объект для setInterval, чтоб его отменить
      intervalForCheckVideoInModal: false, // тут храним объект для setInterval, чтоб его отменить
      videoLoadingStatusByAdID: {}, // используем чтобы скалдывать сюда данные о статусе загрузки видео
      videoLoadingStatusByAdIDForModal: {}, // используем чтобы скалдывать сюда данные о статусе загрузки видео внутри модалки
      isInfoMsgWhenPollsColorThemeChangedShowing: false,
      massUpdateAdlocIds: [],
      updatedAdlocIds: [],
      massUpdatedAd: {},
      massAdsEditTargetingModalShowing: false,
      massAdsEditScheduleModalShowing: false,
      selectedAdsId: [],
      adIdForViewsStatistic: '',
      adNameForViewsStatistic: '',
      adsType: ['video', 'image', 'poll', 'poll_quality', 'poll_user_data'],
      canLoadMoreAds: true,
      currentAdStats: { statistics: [], type: '', data: {} },
      currentRedirectUrl: false,
      dateStartForStatistic: null,
      desktopMuteUpload: false,
      genders: ['female', 'male'],
      hasDesktop: false,
      hasPollPictures: false,
      hasTheme: false,
      isAdsModalOpen: false,
      isAdStatsShowing: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isDesktopUploaded: false,
      isMobileUploaded: false,
      isNewAd: false,
      isPollWarnOpen: false,
      isShowsStatsModalOpen: false,
      isVideoMuted: true,
      mobileMuteUpload: false,
      numberOfVisitsSignType: ['greater', 'equal', 'less'],
      offset: 0,
      shownAdShowsStats: [],
      targetingSettingsShow: false,
      totalResultAds: false,
      updatedAd: false,
      redirectButton: {
        text: {
          ru: 'Подробнее',
          en: 'Details',
          tg: 'Тафсилоти бештар'
        },
        currentLang: 'ru'
      },
      redirectButtonTextForNewAd: {
        text: {
          ru: 'Подробнее',
          en: 'Details',
          tg: 'Тафсилоти бештар'
        }
      },
      newAd: {
        base_location: this.$store.state.userData.base_location,
        loc_ids: [],
        name: '',
        description: '',
        data: {
          type: 'image',
          url: '',
          url_desktop: '',
          question: '',
          poll_variants: [],
          poll_image: [],
          skip: false,
          duration: 5,
          skip_after: 0,
          redirect_url: '',
          self_variant: false,
          theme_color: '',
          theme_type: '',
          poll_fields: {},
          file: '',
          file_desktop: '',
          redirect_button_text: '',
          redirect_button_color: ''
        },
        priority: 0,
        agreement: '',
        platform: {
          desktop: true,
          mobile: true,
          tablet: true
        },
        os: [],
        vendor: [],
        schedule: {
          start: moment().startOf('day').unix(),
          stop: moment().endOf('day').unix(),
          views: 0,
          day_of_week: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true
          },
          time_of_day: {
            morning: true,
            afternoon: true,
            evening: true,
            night: true
          }
        },
        number_of_visits: {
          visits: 0,
          sign: 'greater' //  less or equal or greater
        }
      },
      filters: {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      },
      additionalFilters: {
        ad_types: []
      },
      baseURL: `${window.RESTAPI ? '' : `${window.location.protocol}//`}${
        window.RESTAPI ? window.RESTAPI : window.location.host
      }`,

      // baseURL: `https://nmstest.wimark.tk/api/`,
      scheduleCutoffDate: moment().startOf('day').unix()
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isFiltersActive(){
      return this.filters.limit !== 30 || this.filters.sort_by !== 'name' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 ) ||
        (typeof this.additionalFilters === 'object' && Object.prototype.hasOwnProperty.call(this.additionalFilters, 'ad_types') &&
        Array.isArray(this.additionalFilters.ad_types) && this.additionalFilters.ad_types.length !== 0 )

    },
    isClickToGoToAnotherPage() {
      return this.$store.state.isClickToGoToAnotherPage;
    },
    // isVideosLoading() {
    //   // возвращает true когда хотя-бы одно видео на странице загружается и false в противоположном
    //   // случае
    //   try {
    //     const loadingStatuses = Object.values(this.videoLoadingStatusByAdID);
    //     // еслит в массиве значнеий обхекта вобще нет ничего возвращаем false
    //     if (loadingStatuses.length === 0) {
    //       return false;
    //     }
    //     if (loadingStatuses.every((status) => {
    //       return status === false;
    //     })) {
    //       // если все значния в false (ни одно видео не загружается возвращаем false)
    //       return false;
    //     } else {
    //       // если хотя-бы одно значние в true (какие-то видео загружаются возвращаем true)
    //       return true;
    //     }
    //   } catch (e) {
    //     return false;
    //   }
    // },
    locationsForLocIdInTargeting() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      const locations = JSON.parse(JSON.stringify(this.$store.state.locationsList));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id, loc_id: location.loc_id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id, loc_id: location.loc_id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      // console.log(result)
      return result;
    },
    DAYS_OF_WEEK() {
      return DAYS_OF_WEEK;
    },
    TIME_OF_DAY() {
      return TIME_OF_DAY;
    },
    PLATFORM() {
      return PLATFORM;
    },
    COLORS() {
      return [
        '#EF6C00',
        '#E64919',
        '#FFC400',
        '#00BFA5',
        '#43A047',
        '#BF360C',
        '#1976D2',
        '#039BE5',
        '#795548',
        '#212121',
        '#616161',
        '#673AB7',
        '#D81B60',
        '#FF4081'
      ];
    },
    allAdsSelected() {
      if (this.selectedAdsId.length === this.portalAdsWithStatsFiltered.length) {
        return true;
      }
      return false;
    },
    isAdsSelected() {
      if (this.selectedAdsId.length > 0) {
        return true;
      }
      return false;
    },
    locationsListByAdId() {
      return this.$store.state.portalAdsLocationsList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    muteEdit() {
      return this.$store.state.mutePortalAdEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    portalAdsList() {
      return this.$store.state.portalAdsList;
    },
    portalAdsStatsList() {
      return this.$store.state.portalAdsStatsList;
    },
    portalAdsWithStats() {
      if (this.portalAdsList && this.portalAdsStatsList) {
        const copy = JSON.parse(JSON.stringify(this.portalAdsList));
        this.portalAdsStatsList.forEach((item) => {
          const ad = copy.find((ad) => ad.id === item.id);
          if (ad) {
            ad.statistics = {
              counter: item.counter,
              counter_skipped: item.counter_skipped,
              duration: item.duration,
              poll_counter: item.poll_counter
            };
          }
        });
        return copy;
      }
      return [];
    },
    portalAdsWithStatsFiltered() {
      return this.portalAdsWithStats.filter((ad) => {
        ad.campaignStatus = this.getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop);
        if (!this.additionalFilters.ad_types.length) return true;
        return this.additionalFilters.ad_types.includes(ad.data.type);
      });
    },
    portalAdsByStart() {
      if (this.$store.state.portalAdsList) {
        return JSON.parse(JSON.stringify(this.$store.state.portalAdsList))
          .filter((ad) => ad.schedule.stop >= this.scheduleCutoffDate)
          .sort((a, b) => a.schedule.start - b.schedule.start)
          .map((ad) => {
            ad.campaignStatus = this.getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop);
            return ad;
          });
      }
      return false;
    },
    isPortalAdsLoading() {
      return this.$store.state.loadingPortalAds || this.$store.state.loadingPortalAdsStats;
    },
    updatedAdTimeToString() {
      const { start } = this.updatedAd.schedule;
      const { stop } = this.updatedAd.schedule;
      if (start === 0 || stop === 0) {
        return '';
      }
      const pattern = 'DD MMM YYYY, HH:mm:ss';
      return `${moment.unix(start).format(pattern)} — ${moment.unix(stop).format(pattern)}`;
    }
  },
  watch: {
    updatedAd: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    hasTheme(val) {
      if (val === false) {
        this.updatedAd.data.theme_type = '';
        this.updatedAd.data.theme_color = '';
      }
    },
    redirectButton: {
      deep: true,
      handler() {
        const textToSend = { ...this.redirectButton.text };
        this.updatedAd.data.redirect_button_text = JSON.stringify(textToSend);
      }
    }
    // 'updatedAd.data.poll_variants': {
    //   handler() {
    //     if (this.updatedAd.data.poll_variants.length > 2) {
    //       this.$validator.errors.add({ field: 'field', message: 'message' });

    //       console.log(this.$validator.errors);
    //     }
    //   }
    // }
  },
  // created() {
  //   // this.startWatchForVideoStatuses();
  //   this.clearQuery();
  //   portalAdsService.getPortalAds(this, { query: true });
  //   portalAdsService.getAdStatistics(this);
  // },

  created() {
    // this.startWatchForVideoStatuses();
    this.clearQuery();


    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }


    // portalAdsService.getPortalAds(this, { query: true });
    portalAdsService.getPortalAds(this, {query: true, useLocationFilter: true});
    portalAdsService.getAdStatistics(this);
  },

  mounted() {
    if (this.$route.params.adId && this.$route.params.adName) {
      this.openAdsStats(this.$route.params.adId, this.$route.params.adName);
    }
    if (this.$route.params.start) {
      this.dateStartForStatistic = this.$route.params.start;
      this.isAdStatsShowing = true;
    }
    if (this.$route.params.adStatsShowing) {
      this.isAdStatsShowing = true;
    }
  },
  // updated() {
  //   const videos = document.getElementsByClassName('loading-media-in-card-on-page');
  //   if (videos) {
  //     videos.forEach((element) => {
  //       // снимаем с элементов старые обрботички событий
  //       element.removeEventListener('loadeddata', this.handleLoadeddataEventVhenVideoLoading)
  //       if (element.readyState === 0) {
  //         // если элемент не загружен  (0 = HAVE_NOTHING - no information whether or not the audio/video is ready)
  //         // добавляем в объект информацию о том что он грузиться, навешиваем событие, чтобы по кончанию загрузки он поменял значение в объекте
  //         this.$set(this.videoLoadingStatusByAdID, element.dataset.id, true);
  //         element.addEventListener('loadeddata', this.handleLoadeddataEventVhenVideoLoading);
  //       } else {
  //         // а если какие-то данные по нему есть, то сразузаносим в объект что он не загружается
  //         this.$set(this.videoLoadingStatusByAdID, element.dataset.id, false);
  //       }
  //     });
  //     // console.log(videos)
  //   }
  // },
  beforeDestroy() {
    this.stopWatchForVideoStatuses();
    this.stopWatchForVideoStatusesInModal();
  },
  updated() {
    // this.startWatchForVideoStatuses();
  },

  methods: {
    reloadVideoByAdId(adId) {
      // ищет нужный элемент с видео и релоадит его
      try {
        // ищем элемент с видео по названию класса и id рекламы
        const video = document.querySelector(`[data-id="${adId}"].loading-media-in-card-on-page `);
        if (video) {
          // если нашли релоадим
          video.load();
          this.startWatchForVideoStatuses();
        }
      } catch (e) {
        console.log(e);
      }
    },
    redrawTablePaginationV3Component() {
      this.showTablePaginationV3Component = false;
      this.$nextTick(() => {
        this.showTablePaginationV3Component = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'name',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   };
    //   this.additionalFilters = {
    //     ad_types: []
    //   };
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    //
    // },
    resetAllFilters() {
      this.filters = {
        limit: 30,
        offset: 0,
        sort_by: 'name',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        //location: {
        //  data: {},
        //  with_childs: false,
        // }
      };
      this.additionalFilters = {
        ad_types: []
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();

    },
    reloadVideoByAdIdForModal(adId) {
      // ищет нужный элемент с видео и релоадит его
      try {
        // ищем элемент с видео по названию класса и id рекламы
        const video = document.querySelector(`[data-id="${adId}"].loading-media-in-modal`);
        if (video) {
          // если нашли релоадим
          video.load();
          // this.checkVideoStatusInModal();
          this.startWatchForVideoStatusesInModal()
        }
      } catch (e) {
        console.log(e);
      }
    },
    clearVideoLoadingStatusByAdIDForModalObject() {
      // console.log('clear')
      this.videoLoadingStatusByAdIDForModal = {};
    },
    startWatchForVideoStatuses() {
      if (this.intervalForCheckVideo !== null) {
        clearInterval(this.intervalForCheckVideo);
      }
      this.checkVideoStatus();
      this.intervalForCheckVideo = setInterval(this.checkVideoStatus, 2000);
    },
    startWatchForVideoStatusesInModal() {
      if (this.intervalForCheckVideoInModal !== null) {
        clearInterval(this.intervalForCheckVideoInModal);
      }
      this.checkVideoStatusInModal();
      this.intervalForCheckVideoInModal = setInterval(this.checkVideoStatusInModal, 500);
    },
    stopWatchForVideoStatuses() {
      if (this.intervalForCheckVideo !== null) {
        clearInterval(this.intervalForCheckVideo);
      }
    },
    stopWatchForVideoStatusesInModal() {
      if (this.intervalForCheckVideoInModal !== null) {
        clearInterval(this.intervalForCheckVideoInModal);
      }
    },
    checkVideoStatus() {
      // функция запускается черз интервал, находит на странице элементы <video>, которые расположены
      // в плтиках реклам по классу (loading-media-in-card-on-page) и следит за их статусом
      // (загружено, загружается, ошибка загрузки), занося все в специальный объект
      try {
        // console.log('checking video');
        const videos = document.getElementsByClassName('loading-media-in-card-on-page');
        if (videos) {
          videos.forEach((element) => {
            // console.log(element.error);
            // console.log(element.readyState )
            if (element.readyState === 0) {
              // если элемент не загружен  (0 = HAVE_NOTHING - no information whether or not the audio/video is ready)
              // добавляем в объект информацию о том что он грузиться, навешиваем событие, чтобы по кончанию загрузки он поменял значение в объекте
              this.$set(this.videoLoadingStatusByAdID, element.dataset.id, true);
            } else {
              // а если какие-то данные по нему есть, то сразузаносим в объект что он не загружается
              this.$set(this.videoLoadingStatusByAdID, element.dataset.id, false);
            }
            if (element.error) {
              // если же ошибка то пихаем туда что ошибка
              this.$set(this.videoLoadingStatusByAdID, element.dataset.id, 'error');
            }
          });
          // console.log(videos)
        }
      } catch (e) {
        // если что то  пошло не так, останавливаем повторные запуски функции
        console.log(e);
        this.stopWatchForVideoStatuses();
      }
    },
    checkVideoStatusInModal() {
      // console.log('check')
      // функция запускается черз интервал, находит на странице в модалке элементы <video>
      // по классу loading-media-in-modal
      try {
        // console.log('checking video');
        const videos = document.getElementsByClassName('loading-media-in-modal');
        if (videos) {
          videos.forEach((element) => {
            // console.log(element.error);
            // console.log(element.readyState )
            if (element.readyState === 0) {
              // если элемент не загружен  (0 = HAVE_NOTHING - no information whether or not the audio/video is ready)
              // добавляем в объект информацию о том что он грузиться
              this.$set(this.videoLoadingStatusByAdIDForModal, element.dataset.id, true);
            } else {
              // а если какие-то данные по нему есть, то сразузаносим в объект что он не загружается
              this.$set(this.videoLoadingStatusByAdIDForModal, element.dataset.id, false);
              this.stopWatchForVideoStatusesInModal();
            }
            if (element.error) {
              // console.log('push error', element.dataset.id)
              // если же ошибка то пихаем туда что ошибка
              this.$set(this.videoLoadingStatusByAdIDForModal, element.dataset.id, 'error');
              this.stopWatchForVideoStatusesInModal();
            }
          });
          // console.log(videos)
        }
      } catch (e) {
        // если что то  пошло не так, останавливаем повторные запуски функции
        console.log(e);
        this.stopWatchForVideoStatusesInModal();
      }
    },
    isVideosLoadingForAdId(adId) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.videoLoadingStatusByAdID, adId) &&
          this.videoLoadingStatusByAdID[adId] === true
        ) {
          return true;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.videoLoadingStatusByAdID, adId) &&
          this.videoLoadingStatusByAdID[adId] === 'error'
        ) {
          return 'error';
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isVideosLoadingForAdIdForModal(adId) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.videoLoadingStatusByAdIDForModal, adId) &&
          this.videoLoadingStatusByAdIDForModal[adId] === true
        ) {
          return true;
        }
        if (
          Object.prototype.hasOwnProperty.call(this.videoLoadingStatusByAdIDForModal, adId) &&
          this.videoLoadingStatusByAdIDForModal[adId] === 'error'
        ) {
          return 'error';
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    // handleLoadeddataEventVhenVideoLoading(event) {
    //   try {
    //     this.$set(this.videoLoadingStatusByAdID, event.target.dataset.id, false);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    showInfoMsgWhenRedirectButtonColorChanged() {
      // console.log('changed');
      this.isInfoMsgWhenRedirectButtonColorChangedShowing = true;
    },
    showInfoMsgWhenPollsColorThemeChanged() {
      // console.log('changed');
      this.isInfoMsgWhenPollsColorThemeChangedShowing = true;
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      return locationService.requestLocationsWithSort(searchQuery);
    },
    selectInSameAs() {
      // поле this.updatedAd успевает  заполняется только в следюущем тике
      this.$nextTick(() => {
        // копируем настройки loc id
        if (this.updatedAd.loc_ids === null) {
          this.updatedAd.loc_ids = [];
        }
        this.updatedAdlocIds = this.updatedAd.loc_ids.reduce((result, locId) => {
          const location = commonService.locationObjbyLocId(locId);
          if (location) {
            result.push({
              name: location.name,
              id: location.id,
              loc_id: location.loc_id
            });
          }
          return result;
        }, []);
      });
    },
    clearSelectedAdsId() {
      this.selectedAdsId = [];
    },
    closeAllMassAdsEditModals() {
      this.massAdsEditScheduleModalShowing = false;
      this.massAdsEditTargetingModalShowing = false;
    },
    massUpdateAdsTargeting() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          // console.log('targeting UPD');
          const result = [];
          const adsData = JSON.parse(JSON.stringify(this.$store.state.portalAdsList));
          // ищем данные по выбранным id рекламы
          const selectedAdsData = adsData.filter((item) => {
            if (this.selectedAdsId.includes(item.id)) {
              return true;
            }
            return false;
          });
          // добавляем к каждой выбраной рекламе  массово измененные поля блока targeting
          selectedAdsData.forEach((item) => {
            item.schedule.day_of_week = this.massUpdatedAd.schedule.day_of_week;
            item.schedule.time_of_day = this.massUpdatedAd.schedule.time_of_day;
            item.os = this.massUpdatedAd.os;
            item.vendor = this.massUpdatedAd.vendor;
            item.platform = this.massUpdatedAd.platform;
            item.number_of_visits = this.massUpdatedAd.number_of_visits;
            item.loc_ids = this.massUpdateAdlocIds.map((location) => location.loc_id);
          });
          // а теперь добавляем к каждой рекламе ее локацию, чтобы отправить на сервер.
          selectedAdsData.forEach((item) => {
            const adWithLocation = {
              request: [item],
              base_location: this.locationsListByAdId[item.id]
            };
            result.push(adWithLocation);
          });
          // console.log(result);
          portalAdsService.massUpdateAd(this, result);
        }
      });
    },
    massUpdateAdsSchedule() {
      const result = [];
      const adsData = JSON.parse(JSON.stringify(this.$store.state.portalAdsList));
      // ищем данные по выбранным id рекламы
      const selectedAdsData = adsData.filter((item) => {
        if (this.selectedAdsId.includes(item.id)) {
          return true;
        }
        return false;
      });
      // добавляем к каждой выбранной рекламе измененные таймстампы начала и конца показ рекламы
      selectedAdsData.forEach((item) => {
        item.schedule.start = this.massUpdatedAd.schedule.start;
        item.schedule.stop = this.massUpdatedAd.schedule.stop;
      });

      // а теперь добавляем к каждой рекламе ее локацию, чтобы отправить на сервер.
      selectedAdsData.forEach((item) => {
        const adWithLocation = {
          request: [item],
          base_location: this.locationsListByAdId[item.id]
        };
        result.push(adWithLocation);
      });
      // console.log(result);
      portalAdsService.massUpdateAd(this, result);
    },
    massAdsEditScheduleShowModal() {
      // подтягиваем такие-же настройки по-умолчанию как и для новой рекламы
      this.massUpdatedAd = {};
      const newAd = JSON.parse(JSON.stringify(this.newAd));
      this.massUpdatedAd.schedule = {};
      this.massUpdatedAd.schedule = {
        start: newAd.schedule.start,
        stop: newAd.schedule.stop
      };
      // показываем модалку
      this.massAdsEditScheduleModalShowing = true;
    },
    massAdsEditScheduleCloseModal() {
      this.massAdsEditScheduleModalShowing = false;
      this.massUpdatedAd = {};
    },
    massAdsEditTargetingShowModal() {
      // подтягиваем такие-же настройки по-умолчанию как и для новой рекламы
      this.massUpdatedAd = {};
      const newAd = JSON.parse(JSON.stringify(this.newAd));
      this.massUpdatedAd.schedule = {};
      this.massUpdatedAd.schedule = {
        day_of_week: newAd.schedule.day_of_week,
        time_of_day: newAd.schedule.time_of_day
      };
      this.massUpdatedAd.os = newAd.os;
      this.massUpdatedAd.vendor = newAd.vendor;
      this.massUpdatedAd.platform = newAd.platform;
      this.massUpdatedAd.number_of_visits = newAd.number_of_visits;
      // показываем модалку
      this.massAdsEditTargetingModalShowing = true;
    },
    massAdsEditTargetingCloseModal() {
      this.massAdsEditTargetingModalShowing = false;
      this.massUpdatedAd = {};
    },
    toggleSelectAllAds() {
      if (this.allAdsSelected === true) {
        this.selectedAdsId = [];
      } else {
        this.selectedAdsId = [];
        this.portalAdsWithStatsFiltered.forEach((ad) => {
          this.selectedAdsId.push(ad.id);
        });
      }
    },
    selectAd(id) {
      // console.log(id);
      if (this.selectedAdsId.includes(id)) {
        this.selectedAdsId.splice(this.selectedAdsId.indexOf(id), 1);
        // console.log('delete ' + id);
      } else {
        this.selectedAdsId.push(id);
        // console.log('add'  + id);
      }
    },
    checkAdActivityStatus(ad) {
      // if (ad.schedule && ad.schedule.stop !== undefined && ad.schedule.start !== undefined) {
      //   if (ad.schedule.stop >= moment().startOf('day').unix()
      //     && ad.schedule.start <= moment().startOf('day').unix()) {
      //     return 'active';
      //   }
      //   if (ad.schedule.stop > moment().startOf('day').unix()
      //     && ad.schedule.start > moment().startOf('day').unix()) {
      //     return 'planned';
      //   }
      //   return 'done';
      // }
      // return null;
      if (ad.schedule && ad.schedule.stop !== undefined && ad.schedule.start !== undefined) {
        if (this.getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop) === 'done') {
          return 'done';
        }
        if (this.getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop) === 'planned') {
          return 'planned';
        }
        if (this.getAdStatus(moment().unix(), ad.schedule.start, ad.schedule.stop) === 'active') {
          return 'active';
        }
      }
      return null;
    },
    // toggleVideoSound() {
    //   const video = this.$refs.videoMobile;
    //   video.muted = !video.muted;
    //   this.isVideoMuted = !this.isVideoMuted;
    // },
    toggleVideoSound() {
      const video = this.$refs.videoMobile;
      if (video) {
        video.muted = !video.muted;
        this.isVideoMuted = !this.isVideoMuted;
      }
    },
    toPercent(part, full) {
      return ((part / full) * 100).toFixed(2);
    },
    showAdShowsStats(adId) {
      if (this.shownAdShowsStats.includes(adId)) {
        this.shownAdShowsStats = this.shownAdShowsStats.filter((ad) => ad !== adId);
      } else {
        this.shownAdShowsStats.push(adId);
      }
    },
    redirectTo(url) {
      window.location.assign(url);
    },
    getLocationNameByAdId(adId) {
      let result = '';
      if (this.locationsListByAdId && this.locationsListByAdId[adId]) {
        if (this.locationName(this.locationsListByAdId[adId])) {
          result = this.locationName(this.locationsListByAdId[adId]);
        }
      }
      return result;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    sortedPollStats(ad) {
      const { statistics } = ad;
      if (statistics.poll_counter) {
        return Object.entries(statistics.poll_counter).sort((a, b) => b[1] - a[1]);
      }
      return [];
    },
    openPollResults(ad) {
      this.currentAdStats = ad;
      if (ad.data.type === 'poll_quality') {
        const sortedPollQuality = this.sortedPollStats(ad);
        this.isShowsStatsModalOpen = true;
        this.currentAdStats.statisticsPoll = sortedPollQuality;
        this.currentAdStats.type = 'poll_quality';
      } else if (ad.data.type === 'poll') {
        const sortedPoll = this.sortedPollStats(ad);
        this.isShowsStatsModalOpen = true;
        this.currentAdStats.statisticsPoll = sortedPoll;
        this.currentAdStats.type = 'poll';
      } else {
        this.isShowsStatsModalOpen = true;
        this.currentAdStats.statisticsPoll = null;
      }
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    },
    closeAdsStats() {
      this.isAdStatsShowing = false;
    },
    openAdsStats(adId, adName) {
      if (!adId) {
        this.adIdForViewsStatistic = '';
      } else {
        this.adIdForViewsStatistic = adId;
      }
      if (!adName) {
        this.adNameForViewsStatistic = '';
      } else {
        this.adNameForViewsStatistic = adName;
      }
      this.isAdStatsShowing = true;
    },
    datePickerChangedDateinModal(e) {
      this.$set(this.updatedAd.schedule, 'day_of_week', {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      });
      this.$set(this.updatedAd.schedule, 'time_of_day', {
        morning: true,
        afternoon: true,
        evening: true,
        night: true
      });
    },
    toggleTargetingSettings() {
      this.targetingSettingsShow = !this.targetingSettingsShow;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalAdsService.getPortalAds(this, { query: true });
      portalAdsService.getPortalAds(this, { query: true, useLocationFilter: true  });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMoreAds = true;
      this.$store.commit('portalAdsListClean');
    },
    openAdModal(Ad) {
      this.isFileUploading = false;
      this.$validator.reset();
      this.isAdsModalOpen = true;
      this.isNewAd = false;
      this.isMobileUploaded = false;
      this.isDesktopUploaded = false;
      this.isInfoMsgWhenRedirectButtonColorChangedShowing = false;
      this.isInfoMsgWhenPollsColorThemeChangedShowing = false;
      if (!Ad) {
        this.isNewAd = true;
        this.updatedAd = JSON.parse(JSON.stringify(this.newAd));
        this.redirectButton.text.en = this.redirectButtonTextForNewAd.text.en;
        this.redirectButton.text.ru = this.redirectButtonTextForNewAd.text.ru;
        this.redirectButton.text.tg = this.redirectButtonTextForNewAd.text.tg;
        this.updatedAdlocIds = [];
      } else {
        // this.targetingSettingsShow = false;
        //  иногда прилетает пустой массив, а ингода null, делаем из null пустой массив
        if (Ad.os === null) Ad.os = [];
        if (Ad.vendor === null) Ad.vendor = [];
        if (!Ad.data.hasOwnProperty('file')) {
          this.$set(Ad.data, 'file', '');
        }
        if (!Ad.data.hasOwnProperty('file_desktop')) {
          this.$set(Ad.data, 'file_desktop', '');
        }
        if (Ad.data.hasOwnProperty('redirect_button_text') && Ad.data.redirect_button_text != '') {
          if (JSON.parse(Ad.data.redirect_button_text).hasOwnProperty('ru')) {
            this.$set(this.redirectButton.text, 'ru', JSON.parse(Ad.data.redirect_button_text).ru);
          }
          if (Ad.data.redirect_button_text.hasOwnProperty('en')) {
            this.$set(this.redirectButton.text, 'en', JSON.parse(Ad.data.redirect_button_text).en);
          }
          if (Ad.data.redirect_button_text.hasOwnProperty('tg')) {
            this.$set(this.redirectButton.text, 'tg', JSON.parse(Ad.data.redirect_button_text).tg);
          }
        }
        this.updatedAd = JSON.parse(JSON.stringify(Ad));
        this.$set(this.updatedAd, 'base_location', this.locationsListByAdId[this.updatedAd.id]);
        this.setSwitchesOnOpen();
        if (this.updatedAd.loc_ids === null) {
          this.updatedAd.loc_ids = [];
        }
        this.updatedAdlocIds = this.updatedAd.loc_ids.reduce((result, locId) => {
          const location = commonService.locationObjbyLocId(locId);
          if (location) {
            result.push({ name: location.name, id: location.id, loc_id: location.loc_id });
          }
          return result;
        }, []);
        this.isVideoMuted = true;
        this.clearVideoLoadingStatusByAdIDForModalObject();
        if (this.updatedAd.data.type === 'video') {
          if (this.updatedAd.data.url !== '') {
            this.$set(this.videoLoadingStatusByAdIDForModal, this.updatedAd.id, true);
          }
          this.startWatchForVideoStatusesInModal();
        }

      }
      watcher.reset();
    },
    setFilter(filter) {
      if (this.additionalFilters.ad_types.includes(filter)) {
        this.additionalFilters.ad_types = this.additionalFilters.ad_types.filter((el) => el !== filter);
      } else {
        this.additionalFilters.ad_types.push(filter);
      }
      this.getWithQuery(true);
    },
    closeDeleteModal() {
      this.isDeleteOpen = false;
    },
    showAd(data, id) {
      const url = `${this.baseURL}/api/portal-preview`;
      // const url = `https://nmstest.wimark.tk/api/portal-preview`;
      // console.log(data);
      switch (data.type) {
        // case 'image':
        //   if (!data.url) {
        //     return `<img src='${url}${data.url_desktop}' alt=''
        //               style="max-width:100%;"
        //               />`;
        //   }
        //   return `<img src='${url}${data.url}' alt=''
        //               style="max-width:100%;"
        //               />`;
        // case 'video':
        //   return `<video
        //       loop
        //       muted
        //       controls
        //       preload="metadata"
        //       class="m-auto loading-media-in-card-on-page"
        //       style="max-width:100%;"
        //       data-id="${id}"
        //       src='${url}${data.url}'
        //     ></video>`;
        case 'image':
          if (!data.url) {
            return `<div style="display: flex; justify-content: center; align-items: center; width: 100%; overflow: hidden"">
                      <div>
                        <img src='${url}${data.url_desktop}' alt='' style="max-height:180px;"/>
                       </div>
                     </div>`;
          }
          return `<div style="display: flex; justify-content: center; align-items: center; width: 100%; overflow: hidden">
                    <div>
                      <img src='${url}${data.url}' alt='' style="max-height:180px;"/>
                      </div>
                     </div>`;
        case 'video':
          return `<div style="display: flex; justify-content: center; align-items: center; width: 100%; overflow: hidden"" >
                   <div>
                     <img style="max-height: 180px" src="/static/img/videocam.png"/>
                   </div>
                 </div>`;
        case 'poll':
          let variants = '';
          data.poll_variants.forEach((variant) => {
            variants += `<li><i class="fa fa-circle-o mr-q"></i>${variant}</li>`;
          });
          if (data.self_variant) {
            variants += `<li><i class="fa fa-circle-o mr-q"></i>${this.$t('adsprofile.PollSelfVariant')}</li>`;
          }
          return `<ul class='list-unstyled text-left m-0'>
                                              <li class='mb-q'><b>${data.question}</b></li>
                                              ${variants}
                                           </ul>`;
        case 'poll_quality':
          let variantsForQuality = '';
          data.poll_variants.forEach(() => {
            variantsForQuality += '<i class="fa fa-lg fa-star-o" aria-hidden="true"></i>';
          });
          return `<ul class='list-unstyled text-left m-0'>
                                              <li class='mb-q'><b>${data.question}</b></li>
                                              <span class='d-flex flex-wrap mx-auto justify-content-center'>${variantsForQuality}</span>
                                           </ul>`;
        case 'poll_user_data':
          let variantsForUserData = '';
          const fields = Object.keys(data.poll_fields);
          fields.forEach((variant) => {
            const localizedVariant = this.$t(`adsprofile.pollUserData.${variant}`);
            const badgeColor = data.poll_fields[variant] ? 'success' : 'default';
            variantsForUserData += `<li><small class="badge badge-${badgeColor}">${localizedVariant}</small></li>`;
          });
          return `<ul class='list-unstyled text-left m-0'>
                                              <li class='mb-q'><b>${this.$t('adsprofile.pollUserData.getInfo')}</b></li>
                                              ${variantsForUserData}
                                           </ul>`;
        default:
          return data.url || 'no data';
      }
    },
    showPollResults(data) {},
    uploadMediaToServer(type) {
      this[`${type}MuteUpload`] = true;
      const uploadElement = document.querySelector(`.${type}`);
      if (!uploadElement.files.length) {
        return;
      }

      const formData = new FormData();
      formData.append(uploadElement.name, uploadElement.files[0]);
      this.isFileUploading = true;
      this.axios.post(`${window.RESTAPI || ''}/api/portal/admin/upload`, formData).then(
        (response) => {
          this[`is${helpers.capitalize(type)}Uploaded`] = true;
          this[`${type}MuteUpload`] = false;
          if (type === 'mobile') {
            this.updatedAd.data.file = response.data.data.file;
          } else {
            this.updatedAd.data.file_desktop = response.data.data.file;
          }
          this.isFileUploading = false;
        },
        (err) => {
          this[`${type}MuteUpload`] = false;
          this.isFileUploading = false;
          console.warn(err);
        }
      );
    },
    cancelEditMode() {
      if (this.$refs.videoMobile) {
        this.$refs.videoMobile.pause();
      }
      if (this.$refs.videoDesktop) {
        this.$refs.videoDesktop.pause();
      }
      this.isAdsModalOpen = false;
      this.isDeleteOpen = false;
      this.updatedAd = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
      this.isVideoMuted = true;
      this.isFileUploading = false;
      this.stopWatchForVideoStatusesInModal();
      this.clearVideoLoadingStatusByAdIDForModalObject();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    /**
     * Warn user if poll data exists before flipping the switch
     */
    handlePollSwitch(value) {
      if (this.hasPollPictures === value) {
        return;
      }

      if (
        this.updatedAd.data &&
        ((this.updatedAd.data.poll_variants && this.updatedAd.data.poll_variants.length > 0) ||
          (this.updatedAd.data.poll_image && this.updatedAd.data.poll_image.length > 0))
      ) {
        this.isPollWarnOpen = true;
        return;
      }
      this.hasPollPictures = value;
    },
    handlePollModalConfirm() {
      if (this.hasPollPictures) {
        this.updatedAd.data.poll_image = [];
      } else {
        this.updatedAd.data.poll_variants = [];
      }
      this.isPollWarnOpen = false;
      this.hasPollPictures = !this.hasPollPictures;
      helpers.handleSecondModalHiding();
    },
    handlePollModalCancel() {
      this.isPollWarnOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleShowsStatsModalClose() {
      this.isShowsStatsModalOpen = false;
      // helpers.handleSecondModalHiding();
    },
    updateAd(Page) {
      this.updatedAd.loc_ids = this.updatedAdlocIds.map((location) => location.loc_id);
      this.$validator.validate().then((valid) => {
        if (valid) {
          portalAdsService.updateAd(this, Page);
        }
      });
    },
    createAd(Page) {
      this.updatedAd.loc_ids = this.updatedAdlocIds.map((location) => location.loc_id);
      this.$validator.validate().then((valid) => {
        if (valid) {
          portalAdsService.createAd(this, Page);
        }
      });
    },
    deleteAd() {
      portalAdsService.deleteAd(this, this.updatedAd);
    },
    getAdStatus,
    getWithQuery() {
      // добавляем очистку выбранных реклам
      this.selectedAdsId = [];
      this.clearQuery();
      // portalAdsService.getPortalAds(this, { query: true });
      portalAdsService.getPortalAds(this, { query: true, useLocationFilter: true  });
    },
    getWithQueryAfterMassEdit() {
      // не очищаем список выброанных реклам, в отличии от getWithQuery
      // чтобы можно было еще что-нибудь поменять массово для этих-же реклам
      this.clearQuery();
      // portalAdsService.getPortalAds(this, { query: true });
      portalAdsService.getPortalAds(this, { query: true, useLocationFilter: true  });
    },
    handleSelectColor(payload) {
      if (this.updatedAd === false || this.updatedAd.data.theme_color === payload) {
        return;
      }
      this.showInfoMsgWhenPollsColorThemeChanged();
      this.updatedAd.data.theme_color = payload;
    },
    handleSelectType(payload) {
      if (this.updatedAd === false || this.updatedAd.data.theme_type === payload) {
        return;
      }
      this.showInfoMsgWhenPollsColorThemeChanged();
      this.updatedAd.data.theme_type = payload;
    },
    setSwitchesOnOpen() {
      if (this.updatedAd.data.poll_image && this.updatedAd.data.poll_image.length > 0) {
        this.hasPollPictures = true;
      } else if (this.updatedAd.data.poll_variants && this.updatedAd.data.poll_variants.length > 0) {
        this.hasPollPictures = false;
      }

      this.hasTheme = !!(
        this.updatedAd.data.theme_color &&
        this.updatedAd.data.theme_color !== '' &&
        this.updatedAd.data.theme_type &&
        this.updatedAd.data.theme_type !== ''
      );

      this.hasDesktop = !!this.updatedAd.data.url_desktop;
    },
    deletePhoto(type) {
      if (type === 'mobile') {
        this.updatedAd.data.file = '';
        this.updatedAd.data.url = '';
      } else {
        this.updatedAd.data.file_desktop = '';
        this.updatedAd.data.url_desktop = '';
      }
      // this.updatedAd.data.url = '';
      this.$validator.reset();
      // this.updateUserData();
    }
  }
};
</script>

<style lang="scss" scoped>
.poll-stats {
  position: fixed;
  // top: 10%;
  // left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
}

.img-thumbnail {
  display: block;
  max-width: 200px;
  border: 0;
}

.video-thumbnail {
  max-width: 100%;
  height: 100%;
}

.ads-schedule {
  margin: 0;
  padding: 0;

  list-style: none;
}

.ads-schedule__status--active {
  color: #4dbd74;
}

.ads-schedule__status--planned {
  color: #f8cb00;
}

.ads-schedule__status--done {
  color: #94a0b2;
}

.ads-schedule .card-header {
  flex-wrap: wrap;

  font-size: 0.875rem;
}

.ads-schedule .card-block {
  padding: 0.75rem 1.25rem;
}
.ads-schedule .card:hover {
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 1px -2px rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-in-out;
}
.ads-schedule .card {
  transition: all 0.3s ease-in-out;
}

.modal {
  .half-width {
    width: 46%;
  }
  .upload {
    line-height: 1.25;
  }
  .text-muted {
    text-overflow: ellipsis;
  }

  .interface-block {
    border-radius: 10px;
    margin: 0 1rem;
    padding: 1.5rem;
    background: rgba(236, 236, 236, 0.45);
  }
}

.custom-control {
  display: block;
}
</style>

<style lang="scss">
.loader-backdrop-portalads:after {
  position: absolute;
  top: 30px;
}
.ads-status-in-card {
  margin-top: 1px !important;
  margin-bottom: 5px !important;
  z-index: 3;
}
</style>

<style lang="scss" scoped>
  .video-in-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: 50;
  }
.img-video-recommendations-mobile {
  position: absolute;
  width: 200px;
  height: 450px;
  color: #ffffff !important;
  font-size: 1rem;
  text-shadow: 4px 5px 60px rgba(0.2, 0.2, 0.2, 0.8);
  z-index: -1;
}

.img-video-recommendations-desktop {
  position: absolute;
  width: 450px;
  height: 250px;
  color: #ffffff !important;
  font-size: 1rem;
  text-shadow: 4px 5px 60px rgba(0.2, 0.2, 0.2, 0.8);
  z-index: -1;
}
.checkbox-in-iterator-card-header {
  margin-right: 0.2em;
}
.loader-all-page-loading:after {
  position: absolute;
  top: 40vh;
}
.star {
  font-size: 1em !important;
  color: #ffa602 !important;
}

.days-of-week .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.time-of-day .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.platform .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.schedule-caption-in-ad {
  clear: both;
}

.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 3px 3px 3px 0;
  user-select: none;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.modal-info-in-ads {
  min-width: 650px;
}
.views-statistic {
  font-size: 0.9em;
}

.fix-overflowed-table {
  word-break: break-all;
  word-wrap: break-word;
}

#schedule {
  width: 100% !important;
}
</style>

<!-- NOTE: icons from http://www.iconarchive.com/show/free-multimedia-icons-by-designbolts/Photo-icon.html -->
<style lang="scss">
/* Upload file */
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
}



.file-upload-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  // border-radius: 100%;

  transition: opacity 0.3s;
}

.file-upload-edit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 3rem;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 10;
}

.file-upload-field {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0;

  width: 100%;
  height: 100%;
  opacity: 0;
}
.file-upload i {
  transition: opacity 0.3s;
}

.file-upload:hover .file-upload-img {
  opacity: 0.2;
}
.file-upload:hover .file-upload-edit {
  opacity: 0.9;
}

.file-upload-icon:hover {
  opacity: 0.5;
}

.file-upload-delete:hover {
  opacity: 0.5;
}
</style>
<style lang="scss" scoped>
.button-preview {
  position: relative;

  /* width: 100%; */
  padding: 0 20px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  border: 0;
  background-color: var(--color-main);

  color: white;
  font-size: var(--text-size-l);
  font-weight: 700;
  text-align: center;
  text-decoration: none;

  transition: background 0.2s;

  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;
}

.button-preview:hover:not(:disabled),
.button-preview:focus {
  background-color: var(--color-light);
}

.button-preview:active {
  background-color: var(--color-dark);
}

.button-preview:disabled {
  cursor: not-allowed;
  color: var(--color-disabled-main);
  background-color: var(--color-disabled-bg);
}

.button-preview--action-form {
  height: 55px;
}
.button-preview--size-full {
  width: 100%;
}

.video-view__next-button-preview {
  position: absolute;
  bottom: 10vh;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.45);

  width: auto;
  min-height: 40px;
  font-family: var(--font-stack-default);

  transform: translateX(-50%);
}

.video-error-wrapper {
  width: 100%;
  height: 2em;
  position: absolute;
  top: -2em;
  font-size: 0.9em;
}

.video-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.video-reload-wrapper {
  width: 100%;
  height: 2em;
  position: absolute;
  bottom: -2em;
  font-size: 0.9em;
  left: 0;
}

.video-reload-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.video-error-wrapper-in-modal {
  font-size: 1.2em;
}

.video-error-container-in-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-reload-wrapper-in-modal {
  font-size: 1em;
}

.video-reload-container-in-modal {
  justify-content: center;
  align-items: center;
}
</style>
